import { Keyword, Record } from "../features/examination/examination";

export interface Page<T> {
  count: number;
  offset: number;
  pageSize: number;
  data: T[];
}

export const setAnswerForQuestionId = (
  id: number,
  value: string,
  record: Record
): Record => {
  return {
    ...record,
    keywords: record.keywords.map((keyword) => {
      return setQuestionWithIdAnswer(
        id,
        value,
        keyword
      )
    })
  }
}

const setQuestionWithIdAnswer = (
  id: number,
  value: string,
  baseKeyword: Keyword
): Keyword => {
  if (baseKeyword.id === id) {
    baseKeyword.answer = value;
  } else {
    if (baseKeyword.children && baseKeyword.children.length > 0) {
      baseKeyword.children = baseKeyword.children.map((keyword) =>
        setQuestionWithIdAnswer(id, value, keyword)
      );
    }
  }
  return baseKeyword;
};
