import styled, { css } from "styled-components";

export const TabBar = ({
  tabs,
  tabSelected,
  locked,
  activeTabIdx,
  addTabCallback,
}: {
  tabs: string[];
  tabSelected: (idx: number) => void;
  locked?: boolean;
  activeTabIdx: number;
  addTabCallback?: () => void;
}) => {
  return (
    <TabBarWrapper>
      {tabs.map((tab, i) => (
        <span
          key={i}
          onClick={() => {
            if (!locked) {
              tabSelected(i);
            }
          }}
        >
          <StyledTab locked={locked} active={activeTabIdx === i}>
            {tab}
          </StyledTab>
        </span>
      ))}
      {addTabCallback ? (
        <AddTabButton
          locked={locked}
          onClick={() => {
            if (!locked) {
              addTabCallback();
            }
          }}
        >
          +
        </AddTabButton>
      ) : null}
    </TabBarWrapper>
  );
};

const TabBarWrapper = styled.div`
  border-bottom: 1px solid #ccc;
  padding-left: 10px;
`;

const StyledTab = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: -1px;
  margin-right: -1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background: #e0e0e1;
  color: #6a6a6a;
  cursor: pointer;
  font-size: 12px;
  user-select: none;

  ${(props: { active?: boolean; locked?: boolean }) => {
    if (props.active) {
      return css`
        background: #f4f5f5;
        color: black;
        border-bottom: 1px solid #f4f5f5;
      `;
    }

    if (props.locked) {
      return css`
        cursor: no-drop;
        background: #d2d2d2;
        color: #878787;
        border-bottom: 1px solid #f4f5f5;
      `;
    }
  }}
`;

const AddTabButton = styled.div`
  padding: 4px 10px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;

  ${(props: { locked?: boolean }) => {
    if (props.locked) {
      return css`
        cursor: no-drop;
        color: #9a9a9a;
      `;
    }
  }}
`;
