import { createSlice, isDraft, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";
import { Clinic, Configuration, PatientType, User } from "./admin";
import { Record } from "../examination/examination";

export interface AdminState {
  availableClinics: Clinic[];
  availableUsers: User[];
  recordTemplates: Record[];
  availablePatientTypes: PatientType[];
  configuration?: Configuration;
  draftConfiguration?: Configuration;
  loading: boolean;
  isDirty: boolean;
}

export interface QuestionResponse {
  id: number;
  value: string;
}

export const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    availableClinics: [],
    availableUsers: [],
    recordTemplates: [],
    availablePatientTypes: [],
    configuration: undefined,
    draftConfiguration: undefined,
    loading: false,
    isDirty: false,
  } as AdminState,
  reducers: {
    configurationLoading: (state) => {
      state.loading = true;
    },
    configurationReceived: (state, action: PayloadAction<Configuration>) => {
      state.configuration = action.payload;
      state.draftConfiguration = action.payload;
      state.loading = false;
    },
    setAvailablePatientTypes: (state, action: PayloadAction<PatientType[]>) => {
      state.availablePatientTypes = action.payload;
    },
    setAvailableClinics: (state, action: PayloadAction<Clinic[]>) => {
      state.availableClinics = action.payload;
    },
    setAvailableUsers: (state, action: PayloadAction<User[]>) => {
      state.availableUsers = action.payload;
    },
    setRecordTemplates: (state, action: PayloadAction<Record[]>) => {
      state.recordTemplates = action.payload;
    },
    setConfiguration: (state, action: PayloadAction<Configuration>) => {
      state.configuration = action.payload;
    },
    setDraftConfiguration: (state, action: PayloadAction<Configuration>) => {
      state.isDirty = true;
      state.draftConfiguration = action.payload;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
  },
});

export const getConfiguration = () => async (dispatch: Dispatch<any>) => {
  dispatch(AdminSlice.actions.configurationLoading());
  const configuration = await HttpClient.getConfiguration((error) => {
    dispatch(displayError(`Could not fetch configuration: ${error}`));
  });
  const availableClinics = await HttpClient.getAllClinics((error) => {
    dispatch(displayError(`Could not fetch clinics: ${error}`));
  });
  const recordTemplates = await HttpClient.getRecordTemplates((error) => {
    dispatch(displayError(`Could not fetch record templates: ${error}`));
  });
  const users = await HttpClient.getUsers((error) => {
    dispatch(displayError(`Could not fetch users: ${error}`));
  });
  const patientTypes = await HttpClient.getPatientTypes((error) => {
    dispatch(displayError(`Could not fetch patient types: ${error}`));
  });

  dispatch(AdminSlice.actions.configurationReceived(configuration));
  dispatch(AdminSlice.actions.setRecordTemplates(recordTemplates));
  dispatch(AdminSlice.actions.setAvailablePatientTypes(patientTypes));
  dispatch(AdminSlice.actions.setAvailableClinics(availableClinics));
  dispatch(AdminSlice.actions.setAvailableUsers(users));
};

export const updateConfiguration =
  (updatedConfiguration: Configuration) => async (dispatch: Dispatch<any>) => {
    await HttpClient.saveConfiguration(updatedConfiguration, (error) => {
      dispatch(displayError(`Could not save configuration: ${error}`));
    });
    dispatch(AdminSlice.actions.setConfiguration(updatedConfiguration));
    dispatch(AdminSlice.actions.setIsDirty(false));
  };

export const resetSelectedConfiguration =
  (examination: Configuration) => async (dispatch: Dispatch<any>) => {
    dispatch(AdminSlice.actions.setDraftConfiguration(examination));
    dispatch(AdminSlice.actions.setIsDirty(false));
  };

export const { setDraftConfiguration } = AdminSlice.actions;

export default AdminSlice.reducer;
