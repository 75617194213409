import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";

export type ErrorMessage = {
  error: string;
  key: string;
};

export interface ErrorState {
  errors: ErrorMessage[];
}

export const ErrorSlice = createSlice({
  name: "error",
  initialState: {
    errors: [],
  } as ErrorState,
  reducers: {
    addError: (state, action: PayloadAction<string>) => {
      state.errors.push({
        error: action.payload,
        key: Math.random().toString(),
      });
    },
    popError: (state) => {
      state.errors.pop();
    },
  },
});

const { addError, popError } = ErrorSlice.actions;

export const displayError = (error: any) => async (dispatch: Dispatch<any>) => {
  dispatch(addError(error + ""));
  await new Promise((r) => setTimeout(r, 10000));
  dispatch(popError());
};

export default ErrorSlice.reducer;
