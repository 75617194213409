import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect } from "react";
import { ReferralEditorModal } from "./editors/referral-editor-modal";
import { ReferralReturnModal } from "./editors/referral-return-modal";

export const ReferralEditorContainer = () => {
  const { referral, isDirty, isReturning } = useSelector(
    (state: RootState) => state.referralEditor
  );

  useEffect(() => {
    window.onbeforeunload = isDirty
      ? function () {
          return true;
        }
      : null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const availableProducts = useSelector(
    (state: RootState) => state.product.products
  );

  const clinics = useSelector(
    (state: RootState) => state.referral.availableClinics
  );

  const selectableProducts = availableProducts.filter(
    (product) =>
      !referral?.products.find(
        (productInReferral) => productInReferral.code === product.code
      )
  );

  if (!referral) {
    return null;
  }

  if (isReturning) {
    return <ReferralReturnModal referral={referral} clinics={clinics} />;
  }

  return (
    <ReferralEditorModal
      referral={referral}
      clinics={clinics}
      dirty={isDirty}
      availableProducts={selectableProducts}
    />
  );
};
