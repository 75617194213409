import styled from "styled-components";

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
`;

export const Section = styled.div`
  box-shadow: 0 0 5px 0 #999;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const QuestionTitle = styled.div`
  margin: 10px 0 5px 0;
  font-size: 14px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  height: 35px;
  border-radius: 5px;
  padding: 0 5px;
  border: 1px solid #ccc;
`;

export const TextArea = styled.textarea`
  height: 200px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
`;
