import styled from "styled-components";

const UrgentBackground = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  color: white;
  text-align: center;
  background-color: firebrick;
`;
export const UrgentSymbol = () => {
  return <UrgentBackground>!</UrgentBackground>;
};
