import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { HttpClient } from "../../../http-client/http-client";
import { displayError } from "../../error-drawer/error.slice";
import { Examination } from "../examination";

export interface ExaminationListState {
  examinations: Examination[];
  loading: boolean;
}

export const ExaminationsListSlice = createSlice({
  name: "add-examination",
  initialState: {
    examinations: [],
    loading: true,
  } as ExaminationListState,
  reducers: {
    setExaminationsLoading: (state) => {
      state.loading = true;
    },
    examinationsFinishedLoading: (
      state,
      action: PayloadAction<Examination[]>
    ) => {
      state.loading = false;
      state.examinations = action.payload;
    },
  },
});
export const getExaminationsForPatient =
  (patientId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(ExaminationsListSlice.actions.setExaminationsLoading());
    const examinations = await HttpClient.getExaminations(
      patientId,
      (error) => {
        dispatch(displayError(`Could not fetch examinations: ${error}`));
      }
    );
    dispatch(
      ExaminationsListSlice.actions.examinationsFinishedLoading(examinations)
    );
  };

export const { setExaminationsLoading } = ExaminationsListSlice.actions;
