import { useState } from "react";
import { TabBar } from "../../components/tab-bar";
import styled from "styled-components";
import { State } from "../referrals/referral";
import { ReferralEditorContainer } from "../referrals/referral-editor.component";
import { ReferralTab } from "./tabs/referral-tab.component";
import { ExaminationTab } from "./tabs/examination-tab.component";

const tabs = {
  titles: ["Granskning", "2:a granskning", "Pågående", "Skickade"],
};

const ReferralTabWrapper = styled.div`
  padding: 10px;
`;

export const TabNavigation = () => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  return (
    <div>
      <ReferralEditorContainer />
      <TabBar
        activeTabIdx={selectedTabIdx}
        tabs={tabs.titles}
        tabSelected={(idx) => {
          setSelectedTabIdx(idx);
        }}
      />
      <ReferralTabWrapper>{renderTab(selectedTabIdx)}</ReferralTabWrapper>
    </div>
  );
};

const renderTab = (idx: number) => {
  switch (idx) {
    case 0:
      return <ReferralTab key={"referrals-to-verify"} tabState={State.NEW} />;
    case 1:
      return (
        <ReferralTab
          key={"referrals-for-second-verification"}
          tabState={State.NEED_SECOND_VERIFICATION}
        />
      );
    case 2:
      return <ExaminationTab key={"waitlist-examinations"} saved={false} />;
    case 3:
      return <ExaminationTab key={"saved-examinations"} saved={true} />;
    default:
      return null;
  }
};
