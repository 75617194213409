import styled from "styled-components";
import { Button } from "./buttons";
import { render, unmountComponentAtNode } from "react-dom";

const DialogWrapper = styled.div`
  background: white;
  width: 400px;
  height: 150px;
  margin: 200px auto auto;
  padding: 20px;
  border-radius: 5px;
  position: relative;

  &.warning {
    background: #efbcbc;
  }
`;

const ButtonRow = styled.div`
  position: absolute;
  bottom: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;

  &.warning {
    color: #c40000;
  }
`;

const DialogButton = styled(Button)`
  &.warning {
    background: #c40000;
  }
`;

const elementId = "confirm-dialog";
interface ICallback {
  (): void;
}
const ConfirmDialog = ({
  callback,
  text,
  confirmButtonLabel,
  cancelButtonLabel,
  warningDialog,
}: {
  callback: ICallback;
  text: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  warningDialog: boolean;
}) => {
  return (
    <DialogWrapper className={`${warningDialog ? "warning" : ""}`}>
      <Title className={`${warningDialog ? "warning" : ""}`}>{text}</Title>
      <ButtonRow>
        <DialogButton
          onClick={() => {
            callback();
            removeConfirmDialog();
          }}
          className={`${warningDialog ? "warning" : ""}`}
        >
          {confirmButtonLabel}
        </DialogButton>
        <DialogButton
          className={`${!warningDialog ? "warning" : ""}`}
          onClick={() => removeConfirmDialog()}
        >
          {cancelButtonLabel}
        </DialogButton>
      </ButtonRow>
    </DialogWrapper>
  );
};

export const confirmDialog = (
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
  callback: () => void,
  isWarningDialog: boolean = false
) => {
  const element = document.createElement("div");
  element.id = elementId;
  document.body.appendChild(element);
  render(
    <ConfirmDialog
      callback={callback}
      confirmButtonLabel={confirmButtonText}
      cancelButtonLabel={cancelButtonText}
      text={text}
      warningDialog={isWarningDialog}
    />,
    element
  );
};

const removeConfirmDialog = () => {
  const element = document.getElementById(elementId);
  if (element && element.parentNode) {
    unmountComponentAtNode(element);
    element.parentNode.removeChild(element);
  }
};
