import styled from "styled-components";
import { Examination } from "../features/examination/examination";
import React from "react";
import { DateTime } from "luxon";

const SavedReferralWrapper = styled.div`
  padding: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  box-shadow: 0 0 5px 0 #999;
  border-radius: 10px;
  background: white;
`;

const LeftContainer = styled.div`
  width: 200px;
  display: inline-block;
`;
const RightContainer = styled.div`
  display: inline-block;
`;
const Divider = styled.div`
  margin-bottom: 5px;
`;
const Ruler = styled.div`
  border: 1px solid #ccc;
  margin: 20px 0;
`;

export const ExaminationResponseViewer = ({
  examination,
}: {
  examination: Examination;
}) => {
  return (
    <SavedReferralWrapper>
      <b>REMISS</b>
      {"\n"}
      {"\n"}
      <Kv
        label={"Remissdatum"}
        value={DateTime.fromISO(examination.referral.orderTime).toFormat(
          "yyyy-MM-dd HH:mm"
        )}
      />
      <Kv
        label={"Remittent"}
        value={
          examination.referral.sender.name +
          ", " +
          examination.referral.sender.organization +
          ", tel: " +
          examination.referral.sender.phone
        }
      />
      <Kv
        label={"Önskad undersökning"}
        value={examination.referral.requestedExaminations || ""}
      />
      <Kv label={"Frågeställning"} value={examination.referral.diagnosis} />
      <Kv label={"Anamnes/Status"} value={examination.referral.anamnesis} />
      <Kv
        label={"Patient"}
        value={
          examination.referral.patient.firstName +
          " " +
          examination.referral.patient.surName +
          ", " +
          examination.referral.patient.id
        }
      />
      <Ruler />
      <b>SVAR</b>
      {"\n"}
      {"\n"}
      {examination.saved && (
        <Kv
          label={"Svar skickat"}
          value={
            DateTime.fromISO(examination.savedTime).toFormat(
              "yyyy-MM-dd HH:mm"
            ) +
            " av " +
            examination.user.name
          }
        />
      )}
      <Kv
        label={"Undersökningsdatum"}
        value={
          /* Previously, the savedTime was sent in the referral. For old examinations
          with no visitTime, display the value that was used then.
           */
          examination.visitTime
            ? DateTime.fromISO(examination.visitTime).toFormat(
                "yyyy-MM-dd HH:mm"
              )
            : DateTime.fromISO(examination.savedTime).toFormat(
                "yyyy-MM-dd HH:mm"
              )
        }
      />
      <Kv label={"Undersökning"} value={examination.product.name} />
      <Kv label={"Ansvarig"} value={examination.user.name} />
      <p>Slutsvar:{examination.text}</p>
    </SavedReferralWrapper>
  );
};

const Kv = ({ label, value }: { label: string; value: string }) => {
  return (
    <>
      <LeftContainer>{label}:</LeftContainer>
      <RightContainer>{value}</RightContainer>
      <Divider />
    </>
  );
};
