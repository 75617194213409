import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../../components/buttons";
import { useHistory } from "react-router-dom";
import { Table } from "../../../components/table";
import { Examination } from "../examination";
import { UrgentSymbol } from "../../../styles";
import { Title } from "../../../components/form-components";
import {
  getExaminationsForPatient,
  setExaminationsLoading,
} from "./examinations-list.slice";
import { Centered } from "../../../components/centering";

const ContentWrapper = styled.div`
  padding: 20px;
  margin-bottom: 300px;
`;

const TableTitle = styled(Title)`
  margin-top: 0;
`;

export const ExaminationsListComponent = ({
  patientId,
}: {
  patientId: string;
}) => {
  const { examinations, loading } = useSelector((state: RootState) => ({
    examinations: state.examinationsList.examinations,
    loading: state.examinationsList.loading,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setExaminationsLoading());
    dispatch(getExaminationsForPatient(patientId));
    return () => {
      dispatch(setExaminationsLoading());
    };
  }, [dispatch, patientId]);

  const savedExamsHeaders = [
    "Remissdatum",
    "Remittent",
    "Svarsdatum",
    "Svar skickat av",
    "Undersökning",
    "Akut",
  ];
  const availableExamsHeaders = [
    "Remissdatum",
    "Remittent",
    "Undersökning",
    "Akut",
  ];

  if (loading) {
    return <Centered />;
  }

  const availableExaminations = examinations.filter((exam) => !exam.saved);
  const savedExaminations = examinations.filter((exam) => exam.saved);

  return (
    <ContentWrapper>
      <TableTitle>Tillgängliga undersökningar</TableTitle>
      <br />
      <Table
        headers={availableExamsHeaders}
        onSelectRow={(idx) => {
          history.push(
            "examinations/" + availableExaminations[idx].id.toString()
          );
        }}
        rows={availableExaminations.map(AvailableExaminationRow)}
        columnWidths={[100, 300, undefined, 50]}
      />
      <Button
        onClick={() => {
          history.push("examinations/new");
        }}
      >
        Lägg till undersökning
      </Button>

      <Title>Skickade svar</Title>
      <br />
      <Table
        headers={savedExamsHeaders}
        onSelectRow={(idx) => {
          history.push("examinations/" + savedExaminations[idx].id.toString());
        }}
        rows={savedExaminations.map(SavedExaminationRow)}
        columnWidths={[100, 300, 100, 150, undefined, 50]}
      />
    </ContentWrapper>
  );
};

const AvailableExaminationRow = (examination: Examination) => {
  let name = examination.product.name;
  if (examination.version > 1) {
    name = "Komplementsvar - " + name;
  } else if (examination.isReturnExamination) {
    name = "Retur - " + name;
  }
  return [
    examination.referral.orderTime.toString().substring(0, 10),
    `${examination.referral.sender.name}, ${examination.referral.sender.organization}`,
    name,
    examination.referral.urgent ? <UrgentSymbol /> : null,
  ];
};

const SavedExaminationRow = (examination: Examination) => {
  let name = examination.product.name;
  if (examination.version > 1) {
    name = "Komplementsvar - " + name;
  }
  return [
    examination.referral.orderTime.toString().substring(0, 10),
    `${examination.referral.sender.name}, ${examination.referral.sender.organization}`,
    examination.savedTime?.toString().substring(0, 10),
    examination.user.name,
    name,
    examination.referral.urgent ? <UrgentSymbol /> : null,
  ];
};
