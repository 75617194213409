import styled from "styled-components";
import React from "react";

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  z-index: 1;
`;
const Background = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: 20px;
  background: #fff;
  overflow: auto;
`;
const ContentWrapper = styled.div`
  padding: 20px;
`;

export const Modal = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) => {
  return (
    <ModalOverlay onClick={() => onClose && onClose()}>
      <Background onClick={(e) => e.stopPropagation()}>
        <ContentWrapper>{children}</ContentWrapper>
      </Background>
    </ModalOverlay>
  );
};
