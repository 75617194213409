import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "./product";
import { Dispatch } from "react";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";

export interface ProductState {
  loading: boolean;
  products: Product[];
}

export const ProductSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    products: [],
  } as ProductState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload.sort((p1, p2) => {
        return p1.name.localeCompare(p2.name);
      });
      state.loading = false;
    },
    productsLoading: (state) => {
      state.loading = true;
    },
  },
});

export const fetchProducts = () => async (dispatch: Dispatch<any>) => {
  dispatch(ProductSlice.actions.productsLoading);
  const products = await HttpClient.getProducts((error: string) => {
    dispatch(displayError(`Could not fetch products: ${error}`));
  });
  dispatch(
    ProductSlice.actions.setProducts(
      products.filter((product) => !product.hidden)
    )
  );
};

//export const {} = ProductSlice.actions;
export default ProductSlice.reducer;
