import { Referral } from "../features/referrals/referral";
import React from "react";
import { DateTime } from "luxon";
import { Bold, Text } from "./typography";

export const ReferralMetadataViewer = ({
  referral,
}: {
  referral: Referral;
}) => {
  return (
    <div>
      <Bold>Remittent</Bold>
      <Text>
        {referral.sender.name}, {referral.sender.organization}
      </Text>
      <Bold>Remissdatum</Bold>
      <Text>{DateTime.fromISO(referral.orderTime).toFormat("yyyy-MM-dd")}</Text>
    </div>
  );
};
