import { Referral } from "../features/referrals/referral";
import React from "react";
import { Bold, PreWrap, Text } from "./typography";

export const ReferralMiniViewer = ({ referral }: { referral: Referral }) => {
  return (
    <PreWrap>
      <Bold>Anamnes, Status</Bold>
      <Text>{referral.anamnesis}</Text>
      <Bold>Frågeställning</Bold>
      <Text>{referral.diagnosis}</Text>
      <Bold>Kommentar</Bold>
      <Text>{referral.comment}</Text>
      <Bold>Tidigare undersökningar</Bold>
      <Text>{referral.previousExams}</Text>
      <Bold>Önskade undersökningar</Bold>
      <Text>{referral.requestedExaminations}</Text>
    </PreWrap>
  );
};
