import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ErrorMessage } from "./error.slice";

const ErrorBox = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  background: #ffd8d8;
  border: 1px solid #d81212;
  border-radius: 5px;
  padding: 0 10px;
  z-index: 100000;
`;

export const ErrorDrawerContainer = () => {
  const errors = useSelector((state: RootState) => state.error.errors);
  if (errors.length === 0) {
    return null;
  }
  return <ErrorDrawer errors={errors} />;
};

const ErrorDrawer = ({ errors }: { errors: ErrorMessage[] }) => {
  return (
    <ErrorBox>
      {errors.map((error) => (
        <p key={error.key}>{error.error}</p>
      ))}
    </ErrorBox>
  );
};
