import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Referral, State } from "./referral";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";
import { Clinic } from "../admin/admin";
import { ErrorCodes } from "../../error-codes";
import {
  clearDirty,
  setDraftReferral,
  setReferralState,
  setReturnStatus,
} from "./referral-editor.slice";
import { Page } from "../../common/common";
import { IFilter } from "../../components/filters";
import { Record } from "../examination/examination";

export interface ReferralState {
  referrals: Referral[];
  totalCount: number;
  pageSize: number;
  orderBy: string;
  orderDirection: "DESC" | "ASC";
  availableClinics: Clinic[];
  loading: boolean;
}

export const ReferralSlice = createSlice({
  name: "referral",
  initialState: {
    referrals: [],
    totalCount: 0,
    pageSize: 20,
    orderBy: "referral.urgent",
    orderDirection: "DESC",
    availableClinics: [],
    loading: false,
  } as ReferralState,
  reducers: {
    setReferralsLoading: (state) => {
      state.loading = true;
    },
    referralsFinishedLoading: (state) => {
      state.loading = false;
    },
    referralsReceived: (state, action: PayloadAction<Page<Referral>>) => {
      state.referrals = action.payload.data;
      state.totalCount = action.payload.count;
      state.pageSize = action.payload.pageSize;
      state.loading = false;
    },
    setReferral: (state, action: PayloadAction<Referral>) => {
      const idx = state.referrals.findIndex(
        (referral) => referral.id === action.payload.id
      );
      state.referrals[idx] = action.payload;
    },
    setReferrals: (state, action: PayloadAction<Referral[]>) => {
      state.referrals = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      if (state.orderBy === action.payload) {
        state.orderDirection = state.orderDirection === "DESC" ? "ASC" : "DESC";
      } else {
        state.orderDirection = "DESC";
      }
      state.orderBy = action.payload;
    },
    setOrderDirection: (state, action: PayloadAction<string>) => {
      if (action.payload === "DESC" || action.payload === "ASC") {
        state.orderDirection = action.payload;
      }
    },
    setAvailableClinics: (state, action: PayloadAction<Clinic[]>) => {
      state.availableClinics = action.payload;
    },
  },
});

export const { setOrderBy, setOrderDirection, setReferralsLoading } =
  ReferralSlice.actions;

export const getReferrals =
  (
    offset: number,
    pageSize: number,
    state: State,
    orderBy: string,
    orderDirection: "DESC" | "ASC",
    filters: IFilter[]
  ) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(ReferralSlice.actions.setReferralsLoading());
    const referrals = await HttpClient.getReferrals(
      offset,
      pageSize,
      orderBy,
      orderDirection,
      state,
      filters,
      (error) => {
        dispatch(displayError(`Could not fetch referrals: ${error}`));
      }
    );
    const clinics = await HttpClient.getReferralClinics((error) => {
      dispatch(displayError(`Could not fetch clinics: ${error}`));
    });
    dispatch(ReferralSlice.actions.setAvailableClinics(clinics));
    dispatch(ReferralSlice.actions.referralsReceived(referrals));
  };

/*
 * This action creator also dispatches an action to ReferralEditor to set the draft referral when it is received
 */
export const getReferralDetails =
  (referralId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(ReferralSlice.actions.setReferralsLoading());
    const referral = await HttpClient.getReferral(referralId, (error) => {
      dispatch(displayError(`Could not fetch referral: ${error}`));
    });
    if (referral) {
      dispatch(ReferralSlice.actions.setReferral(referral));
      dispatch(setDraftReferral(referral));
    } else {
      dispatch(displayError(`Could not fetch referral`));
    }
    dispatch(ReferralSlice.actions.referralsFinishedLoading());
  };

export const updateReferral =
  (updatedReferral: Referral) => async (dispatch: Dispatch<any>) => {
    const savedReferral = await HttpClient.updateReferral(
      updatedReferral.id,
      {
        state: updatedReferral.state,
        note: updatedReferral.note,
        products: updatedReferral.products,
        urgent: updatedReferral.urgent,
        clinicId: updatedReferral.clinicId,
        clinicName: updatedReferral.clinicName,
      },
      (error) => {
        switch (error.code) {
          case ErrorCodes.PATIENT_COULD_NOT_BE_CREATED_IN_WEBDOC:
            dispatch(
              displayError(
                `Patienten kunde inte skapas i Webdoc och behöver skapas manuellt innan remissen kan sparas. Detta kan bero på att patienten saknar giltigt personnummer eller inte finns i PU. Eventuella ändringar som är gjorda på remissen är ej sparade.`
              )
            );
            break;
          case ErrorCodes.REFERRAL_ALREADY_VERIFIED:
            dispatch(
              displayError(`Remissen är redan granskad och kan inte förändras.`)
            );
            break;

          default:
            dispatch(
              displayError(
                `Kunde inte spara remissen, felmeddelande från server: ${error}`
              )
            );
        }
      }
    );
    if (!savedReferral) {
      return;
    }
    dispatch(ReferralSlice.actions.setReferral(savedReferral));
  };

export const returnReferral =
  (referral: Referral, returnRecord: Record) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(ReferralSlice.actions.setReferralsLoading());
    await dispatch(updateReferral(referral));
    const updatedReferral = await HttpClient.returnReferral(
      referral.id,
      returnRecord,
      () => dispatch(displayError(`Could not return referral`))
    );
    if (!updatedReferral) {
      return;
    }
    dispatch(ReferralSlice.actions.setReferral(updatedReferral));
    dispatch(setReturnStatus(false));
    dispatch(setReferralState(State.RETURNED));
    dispatch(clearDirty());
    dispatch(ReferralSlice.actions.referralsFinishedLoading());
  };

export default ReferralSlice.reducer;
