import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "../../components/filters";

export interface ReferralListState {
  filters: IFilter[];
}

export const ReferralListSlice = createSlice({
  name: "referral-list",
  initialState: {
    filters: [],
  } as ReferralListState,
  reducers: {
    setFilters: (state, action: PayloadAction<IFilter[]>) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = ReferralListSlice.actions;

export default ReferralListSlice.reducer;
