import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { refreshSession } from "./keep-alive.actions";

export const KeepAlive = () => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const id = setTimeout(() => {
      setCounter(counter + 1);
      dispatch(refreshSession());
    }, 60000);
    return () => {
      clearTimeout(id);
    };
  }, [counter, dispatch]);

  return <></>;
};
