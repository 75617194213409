import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { Table } from "../../../components/table";
import { UrgentSymbol } from "../../../styles";
import { Referral, State } from "../../referrals/referral";
import {
  getReferralDetails,
  getReferrals,
  setOrderBy,
} from "../../referrals/referral.slice";
import { DateTime } from "luxon";
import { Filters } from "../../../components/filters";
import { setFilters } from "../referral-list.slice";
import styled from "styled-components";

const Ruler = styled.div`
  height: 10px;
`;

export const ReferralTab = ({ tabState }: { tabState: State }) => {
  const {
    referrals,
    products,
    count,
    pageSize,
    orderBy,
    orderDirection,
    draftReferral,
    filters,
  } = useSelector((state: RootState) => ({
    draftReferral: state.referralEditor.referral,
    referrals: state.referral.referrals.filter(
      (referral) => referral.state === tabState
    ),
    count: state.referral.totalCount,
    pageSize: state.referral.pageSize,
    orderBy: state.referral.orderBy,
    orderDirection: state.referral.orderDirection,
    products: state.product.products,
    filters: state.referralList.filters,
  }));

  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!draftReferral) {
      dispatch(
        getReferrals(
          offset,
          pageSize,
          tabState,
          orderBy,
          orderDirection,
          filters
        )
      );
    }
  }, [
    dispatch,
    draftReferral,
    offset,
    orderBy,
    orderDirection,
    pageSize,
    tabState,
    filters,
  ]);

  const headers = [
    "Personnummer",
    "Namn",
    "Remissdatum",
    "Organisation",
    "Undersökningar",
    "Akut",
  ];
  const sortColumns = [
    "referral.patientId",
    "referral.patientFirstName",
    "referral.orderTime",
    "referral.senderOrganization",
    "products.name",
    "referral.urgent",
  ];
  const sortableColumns = [true, true, true, true, false, true];

  const availableFilters = [
    {
      name: "Undersökning",
      values: products.map((product) => ({
        label: product.name,
        type: "product",
        typeName: "Undersökning",
        value: product.code,
      })),
    },
  ];
  return (
    <>
      <Filters
        filters={filters}
        availableFilters={availableFilters}
        callback={(filter) => {
          setOffset(0);
          dispatch(setFilters([...filters, filter]));
        }}
        deleteFilter={(idx) => {
          setOffset(0);
          dispatch(
            setFilters([...filters.slice(0, idx), ...filters.slice(idx + 1)])
          );
        }}
      />
      <Ruler />
      <Table
        headers={headers}
        rows={referrals.map(referralRow)}
        count={count}
        offset={offset}
        pageSize={pageSize}
        setOffset={(newOffset) => {
          setOffset(newOffset);
        }}
        onSelectRow={(idx) => dispatch(getReferralDetails(referrals[idx].id))}
        sortOn={(columnIdx, direction) => {
          dispatch(setOrderBy(sortColumns[columnIdx]));
        }}
        sortableColumns={sortableColumns}
        sortDirection={orderDirection}
        sortColumn={sortColumns.indexOf(orderBy)}
        columnWidths={[120, 180, 120, 180, undefined, 60]}
      />
    </>
  );
};

const referralRow = (referral: Referral) => {
  return [
    referral.patient.id,
    referral.patient.firstName + " " + referral.patient.surName,
    DateTime.fromISO(referral.orderTime).toFormat("yyyy-MM-dd"),
    referral.sender.organization,
    referral.products.map((p) => p.name).join("; "),
    referral.urgent ? <UrgentSymbol /> : null,
  ];
};
