import styled from "styled-components";
import { useState } from "react";
import { Button } from "./buttons";
import Select from "react-select";

const FilterContainer = styled.div`
  background: hsla(0, 0%, 86.3%, 0.2);
  padding: 8px 8px 0 8px;
  border: 1px solid #e0e0e0;

  border-radius: 8px;
`;

const Filter = styled.div`
  display: inline-block;
  background: #eff2f6;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: #c9cbce;
  }
`;

const AddFilterContainer = styled.div`
  position: absolute;
  width: 400px;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const AddFilterButton = styled(Filter)`
  padding-right: 19px;
  position: relative;
  user-select: none;
`;

const ArrowContainer = styled.div`
  font-size: 9px;
  position: absolute;
  right: 9px;
  top: 14px;
`;

const AddFilterLabel = styled.p`
  font-weight: 600;
  margin: 5px 0 8px 0;
`;

const DeleteFilterIndicator = styled.span`
  margin-left: 5px;
  width: 10px;
  height: 10px;
  padding: 1px 4px;
  border-radius: 10px;
  background: #555;
  color: white;
`;

export interface IFilter {
  label: string;
  type: string;
  typeName: string;
  value: string;
}

export interface IFilterOption {
  name: string;
  values: IFilter[];
}

interface IAddFilterCallback {
  (filter: IFilter): void;
}

interface IDeleteFilter {
  (idx: number): void;
}

export const Filters = ({
  filters,
  availableFilters,
  callback,
  deleteFilter,
}: {
  filters: IFilter[];
  availableFilters: IFilterOption[];
  callback: IAddFilterCallback;
  deleteFilter: IDeleteFilter;
}) => {
  const [showAddFilterContainer, setShowFilterContainer] = useState(false);

  return (
    <FilterContainer>
      <AddFilterButton
        onClick={() => setShowFilterContainer(!showAddFilterContainer)}
      >
        Lägg till filter{" "}
        <ArrowContainer>{showAddFilterContainer ? "▲" : "▼"}</ArrowContainer>
      </AddFilterButton>
      {showAddFilterContainer && (
        <AddFilterPopup
          availableFilters={availableFilters}
          callback={(filter) => {
            setShowFilterContainer(false);
            callback(filter);
          }}
          filters={filters}
        />
      )}
      {filters.map((filter, idx) => (
        <Filter
          onClick={() => {
            deleteFilter(idx);
          }}
        >
          {filter.typeName} = {filter.label}{" "}
          <DeleteFilterIndicator>&#x2715;</DeleteFilterIndicator>
        </Filter>
      ))}
    </FilterContainer>
  );
};

const AddFilterPopup = ({
  availableFilters,
  callback,
  filters,
}: {
  availableFilters: IFilterOption[];
  callback: IAddFilterCallback;
  filters: IFilter[];
}) => {
  const [filterType, setFilterType] = useState<IFilterOption | undefined>(
    undefined
  );
  const [selectedFilter, setSelectedFilter] = useState<IFilter | undefined>(
    undefined
  );

  if (!filterType) {
    if (availableFilters && availableFilters.length > 0)
      setFilterType(availableFilters[0]);
  }

  return (
    <AddFilterContainer>
      <AddFilterLabel>Välj typ av filter</AddFilterLabel>
      <Select
        options={availableFilters.map((filter) => ({
          label: filter.name,
          value: filter,
        }))}
        value={{
          label: filterType?.name,
          value: filterType,
        }}
        onChange={(selectedFilterType) =>
          selectedFilterType && setFilterType(selectedFilterType.value)
        }
      />
      <br />
      <AddFilterLabel>Välj {filterType?.name}</AddFilterLabel>
      <Select
        options={
          filterType
            ? filterType.values
                .filter(
                  (filter) =>
                    !filters.find(
                      (appliedFilter) => appliedFilter.value === filter.value
                    )
                )
                .map((filter) => ({
                  label: filter.label,
                  value: filter,
                }))
            : []
        }
        onChange={(filter) => {
          filter && setSelectedFilter(filter.value);
        }}
        placeholder={""}
      />
      <div />
      <Button
        onClick={() => {
          selectedFilter && callback(selectedFilter);
        }}
      >
        Spara
      </Button>
    </AddFilterContainer>
  );
};
