import styled from "styled-components";

export const Bold = styled.span`
  font-weight: 600;
`;

export const PreWrap = styled.span`
  white-space: pre-wrap;
`;

export const Text = styled.div`
  margin-bottom: 10px;
`;
