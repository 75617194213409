import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Examination } from "./examination";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";
import { Page } from "../../common/common";
import { IFilter } from "../../components/filters";

export interface ExaminationState {
  examinations: Examination[];
  totalCount: number;
  pageSize: number;
  orderBy: string;
  orderDirection: "DESC" | "ASC";
  loading: boolean;
}

export interface QuestionResponse {
  id: number;
  value: string;
}

export const ExaminationSlice = createSlice({
  name: "examination",
  initialState: {
    examinations: [],
    loading: false,
    orderBy: "referral.orderTime",
    orderDirection: "DESC",
    totalCount: 0,
    pageSize: 20,
  } as ExaminationState,
  reducers: {
    setExaminationsLoading: (state) => {
      state.loading = true;
    },
    examinationsReceived: (state, action: PayloadAction<Page<Examination>>) => {
      state.examinations = action.payload.data;
      state.totalCount = action.payload.count;
      state.pageSize = action.payload.pageSize;
      state.loading = false;
    },
    setExamination: (state, action: PayloadAction<Examination>) => {
      const idx = state.examinations.findIndex(
        (referral) => referral.id === action.payload.id
      );
      state.examinations[idx] = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      if (state.orderBy === action.payload) {
        state.orderDirection = state.orderDirection === "DESC" ? "ASC" : "DESC";
      } else {
        state.orderDirection = "DESC";
      }
      state.orderBy = action.payload;
    },
  },
});

export const getExaminationsWithState =
  (
    offset: number,
    pageSize: number,
    saved: boolean,
    orderBy: string,
    orderDirection: "DESC" | "ASC",
    filters: IFilter[]
  ) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(ExaminationSlice.actions.setExaminationsLoading());
    const examinations = await HttpClient.getExaminationsWithState(
      saved,
      offset,
      pageSize,
      orderBy,
      orderDirection,
      filters,
      (error) => {
        dispatch(displayError(`Could not fetch examinations: ${error}`));
      }
    );
    dispatch(ExaminationSlice.actions.examinationsReceived(examinations));
  };

export default ExaminationSlice.reducer;
export const { setOrderBy } = ExaminationSlice.actions;
