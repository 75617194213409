import {
  Keyword,
  KeywordType,
  Record,
} from "../features/examination/examination";
import styled from "styled-components";
import Select from "react-select";
import {
  Input,
  InputWrapper,
  QuestionTitle,
  Section,
  SectionTitle,
  TextArea,
} from "./form-components";
import React from "react";

const ChildWrapper = styled.div``;

const Question = styled.div``;

const CheckBox = styled.input`
  height: 20px;
  width: 20px;
  float: left;
  margin: -1px 6px 0 0;
`;

interface onChange {
  (value: string): void;
}

const handleTab = (
  target: HTMLInputElement | HTMLTextAreaElement,
  e: React.KeyboardEvent
) => {
  const cursorPosition = target.selectionStart;
  const nextAnchorPosition = target.value.indexOf(
    "…",
    typeof cursorPosition === "number" ? cursorPosition + 1 : undefined
  );

  if (nextAnchorPosition >= 0) {
    target.setSelectionRange(nextAnchorPosition, nextAnchorPosition + 1);
    e.preventDefault();
  }
};

const handleFocus = (target: HTMLInputElement | HTMLTextAreaElement) => {
  if (target.value.length > 0 && target.value[0] === "…") {
    target.setSelectionRange(0, 1);
  } else {
    target.setSelectionRange(0, 0);
  }
};

const KeywordInput = ({
  template,
  value,
  onChange,
}: {
  template: Keyword;
  value: string | undefined;
  onChange: onChange;
}) => {
  switch (template.type) {
    case KeywordType.TEXT:
      return (
        <Input
          type="text"
          value={value ? value : ""}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Tab" && !e.shiftKey) {
              const target = e.target as HTMLInputElement;
              handleTab(target, e);
            }
          }}
          onFocus={(e) => {
            const target = e.target as HTMLInputElement;
            handleFocus(target);
          }}
        />
      );
    case KeywordType.DROPDOWN:
      if (template.choices === null) {
        return null;
      }
      return (
        <Select
          value={
            value
              ? {
                  label: value,
                  value: value,
                }
              : null
          }
          onChange={(e) => e && onChange(e.value)}
          options={template.choices.map((choice) => ({
            label: choice,
            value: choice,
          }))}
          placeholder={""}
        />
      );
    case KeywordType.TEXTAREA:
      return (
        <TextArea
          onChange={(e) => e && onChange(e.target.value)}
          value={value !== undefined ? value : ""}
          onKeyDown={(e) => {
            if (e.key === "Tab" && !e.shiftKey) {
              const target = e.target as HTMLTextAreaElement;
              handleTab(target, e);
            }
          }}
          onFocus={(e) => {
            const target = e.target as HTMLTextAreaElement;
            handleFocus(target);
          }}
        />
      );
    case KeywordType.CHECKBOX:
      return (
        <>
          {template.choices &&
            template.choices.map((choice) => (
              <label>
                {choice}
                <CheckBox
                  type="checkbox"
                  checked={value === choice}
                  onChange={(e) =>
                    e && onChange(e.target.checked ? choice : "")
                  }
                />
              </label>
            ))}
        </>
      );

    default:
      return null;
  }
};
export type onQuestionChange = (questionId: number, value: string) => void;

const KeywordView = ({
  template,
  onQuestionChange,
}: {
  template: Keyword;
  onQuestionChange: onQuestionChange;
}) => {
  const change = (value: string) => {
    onQuestionChange(template.id, value);
  };

  if (template.children.length > 0) {
    return (
      <Section>
        <Question>
          <SectionTitle>{template.title}</SectionTitle>
          <InputWrapper>
            <KeywordInput
              value={template.answer}
              template={template}
              onChange={change}
            />
          </InputWrapper>
          <ChildWrapper>
            {template.children.map((keyword) => (
              <KeywordView
                onQuestionChange={onQuestionChange}
                key={keyword.id}
                template={keyword}
              />
            ))}
          </ChildWrapper>
        </Question>
      </Section>
    );
  }

  return (
    <Question>
      <QuestionTitle>{template.title}</QuestionTitle>
      <InputWrapper>
        <KeywordInput
          template={template}
          value={template.answer}
          onChange={change}
        />
      </InputWrapper>
    </Question>
  );
};

export const RecordInput = ({
  record,
  onChange,
}: {
  record: Record;
  onChange: onQuestionChange;
}) => {
  return (
    <div>
      {record.keywords.map((keyword) => (
        <KeywordView
          onQuestionChange={onChange}
          key={keyword.id}
          template={keyword}
        />
      ))}
    </div>
  );
};
