import styled, { css } from "styled-components";
import { ReactNode, useEffect, useState } from "react";

type Row = ReactNode[];

const SortIndicatorWrapper = styled.div`
  margin-left: 5px;
  display: inline-block;
  font-weight: 600;
  visibility: hidden;
  color: #969696;

  ${(props: { active?: boolean }) => {
    if (props.active) {
      return css`
        visibility: visible;
        color: black;
      `;
    }
  }}
`;

const SortIndicator = ({
  sortOrder,
  active,
}: {
  sortOrder: "ASC" | "DESC";
  active: boolean;
}) => {
  if (!active) {
    return <SortIndicatorWrapper>↑</SortIndicatorWrapper>;
  }
  return (
    <SortIndicatorWrapper active={true}>
      {sortOrder === "ASC" ? "↓" : "↑"}
    </SortIndicatorWrapper>
  );
};

export const Table = ({
  headers,
  rows,
  count,
  offset,
  pageSize,
  onSelectRow,
  setOffset,
  sortOn,
  sortableColumns,
  sortColumn,
  sortDirection,
  columnWidths,
}: {
  headers: string[];
  rows: Row[];
  count?: number;
  offset?: number;
  pageSize?: number;
  onSelectRow?: (idx: number) => void;
  setOffset?: (idx: number) => void;
  sortOn?: (columnIdx: number, direction: "ASC" | "DESC") => void;
  sortableColumns?: boolean[];
  sortColumn?: number;
  sortDirection?: "ASC" | "DESC";
  columnWidths?: Array<number | undefined>;
}) => {
  const [sortedRows, setSortedRows] = useState(rows);
  useEffect(() => {
    setSortedRows(rows);
  }, [rows, setSortedRows]);
  return (
    <StyledTableWrapper>
      <StyledTable>
        <TableHeader>
          {headers.map((header, i) => {
            const sortable =
              sortOn &&
              sortableColumns &&
              sortableColumns.length > i &&
              sortableColumns[i] &&
              sortDirection !== undefined &&
              sortColumn !== undefined;

            return (
              <TableHeaderCell
                key={i}
                columnWidth={(() => {
                  if (columnWidths) {
                    if (columnWidths.length > i) {
                      return columnWidths[i];
                    }
                  }
                })()}
                onClick={() => {
                  if (sortOn && sortable) {
                    sortOn(i, "ASC");
                  }
                }}
                sortable={sortable}
              >
                {header}
                {sortable &&
                sortDirection !== undefined &&
                sortColumn !== undefined ? (
                  <SortIndicator
                    sortOrder={sortDirection}
                    active={sortColumn === i}
                  />
                ) : null}
              </TableHeaderCell>
            );
          })}
        </TableHeader>
        <TableBody>
          {sortedRows.map((row, i) => (
            <TableRow
              onClick={() => {
                onSelectRow && onSelectRow(i);
              }}
              clickable={!!onSelectRow}
              key={i}
            >
              {row.map((cell, j) => (
                <TableCell key={j}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      {setOffset &&
        offset !== undefined &&
        count !== undefined &&
        pageSize !== undefined && (
          <PageControls>
            <PageStatus>
              {rows.length > 0 ? offset + 1 : 0} -{" "}
              {offset + pageSize < count ? offset + pageSize : count} av {count}
            </PageStatus>
            <PageSwitcher
              disabled={offset === 0}
              onClick={() => {
                if (offset === 0) {
                  return;
                }
                const newOffset = offset - pageSize > 0 ? offset - pageSize : 0;
                setOffset(newOffset);
              }}
            >
              föregående
            </PageSwitcher>
            <PageSwitcher
              disabled={offset + pageSize >= count}
              onClick={() => {
                if (offset + pageSize >= count) {
                  return;
                }
                setOffset(offset + pageSize);
              }}
            >
              nästa
            </PageSwitcher>
          </PageControls>
        )}
    </StyledTableWrapper>
  );
};

const StyledTableWrapper = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  overflow: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.td`
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  white-space: nowrap;

  ${(props: { sortable?: boolean; columnWidth?: number }) => {
    if (props.sortable) {
      return css`
        cursor: pointer;
      `;
    }
  }}

  width: ${(props: any) =>
    props.columnWidth ? props.columnWidth + "px" : "auto"};

  &:hover {
    ${SortIndicatorWrapper} {
      visibility: visible;
    }
  }
`;

const TableHeaderRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;

const PageControls = styled.div`
  float: right;
  padding: 10px 10px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PageStatus = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const PageSwitcher = styled.div`
  text-decoration: underline;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;

  ${(props: { disabled?: boolean }) => {
    if (props.disabled) {
      return css`
        text-decoration: none;
        color: #a0a0a0;
        cursor: default;
      `;
    }
  }}
`;

const TableHeader = (props: { children: any }) => {
  return (
    <thead>
      <TableHeaderRow>{props.children}</TableHeaderRow>
    </thead>
  );
};

const TableBody = (props: { children: any }) => {
  return <tbody>{props.children}</tbody>;
};

const TableRow = styled.tr`
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  height: 41px;

  &:nth-child(2n) {
    background-color: #e8f9f4;
  }

  ${(props: { clickable?: boolean }) => {
    if (props.clickable) {
      return css`
        &:hover {
          background: #ececec;
          cursor: pointer;
        }
      `;
    }
  }}
`;

const TableCell = styled.td`
  font-size: 14px;
  padding: 10px;
`;
