import { Product } from "../products/product";
import { Referral } from "../referrals/referral";

export enum KeywordType {
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  NUMERIC = "NUMERIC",
  DROPDOWN = "DROPDOWN",
  LABEL = "LABEL",
  CHECKBOX = "CHECKBOX",
}

export enum RecordType {
  NOTE = "NOTE",
  JOURNAL_RECORD = "JOURNAL_RECORD",
  UNKNOWN = "UNKNOWN",
}

export interface Keyword {
  id: number;
  title: string;
  type: KeywordType;
  maxLength: number | null;
  choices: string[] | null;
  answer: string;
  children: Keyword[];
}

export interface Visit {
  id: string;
  clinicId: string;
  clinicName: string;
  date: string;
  careProvider: string;
  recordTemplateId: number;
  recordTemplateName: string;
}

export interface Record {
  id: number;
  type: RecordType;
  title: string;
  keywords: Keyword[];
}

export interface User {
  name: string;
}

export interface Examination {
  id: number;
  patientId: string;
  product: Product;
  referral: Referral;
  record: Record;
  saved: boolean;
  savedTime: string;
  user: User;
  text?: string;
  version: number;
  isLatestVersion?: boolean;
  visitTime?: string;
  visitId?: string;
  hasDraft: boolean;
  isReturnExamination: boolean;
}
