import styled from "styled-components";

export const Button = styled.button`
  margin-top: 20px;
  margin-right: 5px;
  background-color: rgb(63, 81, 181);
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 0, rgba(0, 0, 0, 0.14) 0 0 0 0,
    rgba(0, 0, 0, 0.12) 0 0 0 0;
  text-align: center;
  padding: 10px;
  border: none;

  &:disabled {
    cursor: auto;
    color: #f5f5f5;
    background-color: rgb(151, 154, 180);
  }
`;

export const BlockButton = styled(Button)`
  margin-top: 10px;
  display: block;
`;
