import { Referral, State } from "../referral";
import { Clinic } from "../../admin/admin";
import { Product } from "../../products/product";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  addReferralProduct,
  clearDirty,
  clearDraftReferral,
  removeReferralProduct,
  setReferralClinic,
  setReferralClinicWithoutMakingReferralDirty,
  setReferralNote,
  setReferralState,
  setReferralUrgent,
  setReturnStatus,
} from "../referral-editor.slice";
import { Modal } from "../../../components/modal";
import { UrgentSymbol } from "../../../styles";
import { HttpClient } from "../../../http-client/http-client";
import { displayError } from "../../error-drawer/error.slice";
import Select from "react-select";
import { BlockButton, Button } from "../../../components/buttons";
import { updateReferral } from "../referral.slice";
import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexGrow = styled.div`
  flex: 1;
`;

const Box = styled.div`
  flex-grow: 1;
  border-radius: 5px;
  margin-right: 30px;
`;

const Title = styled.div`
  font-weight: 600;
  margin: 10px 0;
  color: rgba(12, 50, 130, 0.8);
`;

const SubTitle = styled.div`
  margin-top: 10px;
  color: rgba(12, 50, 130, 0.6);
  font-size: 15px;
`;

const UrgentBox = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px 0 0 4px;
`;

const Ruler = styled.div`
  margin: 20px 0;
  height: 1px;
  background: rgba(12, 50, 130, 0.52);
`;

const NotePad = styled.textarea`
  border-radius: 5px;
  border-color: hsl(0, 0%, 70%);
  border-width: 1px;
  width: 100%;
  min-height: 200px;
`;

const TextContainer = styled.div`
  white-space: pre-wrap;
  margin-bottom: 10px;
`;

const CancelButton = styled(BlockButton)`
  background: #f1463a;
`;

const ReturnButton = styled(BlockButton)`
  background: #3b3b3b;
`;

const ExaminationRow = styled.div`
  padding-bottom: 15px;
  position: relative;
`;

const RemoveExaminationButton = styled(Button)`
  border-radius: 23px;
  height: 23px;
  width: 23px;
  padding: 3px 7px;
  margin: -2px 0 0 10px;
  background: #f1463a;
  position: absolute;
`;

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: rgb(63, 81, 181);
  margin-top: 20px;
`;

export const ReferralEditorModal = ({
  referral,
  clinics,
  availableProducts,
  dirty,
}: {
  referral: Referral;
  clinics: Clinic[];
  availableProducts: Product[];
  dirty: boolean;
}) => {
  const dispatch = useDispatch();

  const [selectedProduct, setSelectedProduct] = useState(
    null as Product | null
  );

  const addProduct = () => {
    if (selectedProduct) {
      dispatch(addReferralProduct(selectedProduct));
      setSelectedProduct(null);
    }
  };

  const selectableProducts = availableProducts.map((product) => ({
    label: product.name,
    value: product,
  }));

  const selectableClinics = clinics.map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  if (!referral.clinicId && selectableClinics.length > 0) {
    const clinic = selectableClinics[0];
    dispatch(
      setReferralClinicWithoutMakingReferralDirty({
        id: clinic.value,
        name: clinic.label,
      })
    );
  }

  const readOnly =
    (referral.state === State.VERIFIED || referral.state === State.RETURNED) &&
    !dirty;

  return (
    <Modal onClose={() => !dirty && dispatch(clearDraftReferral())}>
      <FlexRow>
        <FlexGrow>
          <Title>Patient</Title>
          <Box>
            <div>
              <SubTitle>Personnummer</SubTitle>
              <TextContainer>{referral.patient.id}</TextContainer>
              <SubTitle>Namn</SubTitle>
              <TextContainer>
                {referral.patient.firstName} {referral.patient.surName}
              </TextContainer>
              <SubTitle>Födelsedatum</SubTitle>
              <TextContainer>{referral.patient.birthDate}</TextContainer>
              <SubTitle>Tel hem</SubTitle>
              <TextContainer>{referral.patient.phoneHome}</TextContainer>
              <SubTitle>Tel arb</SubTitle>
              <TextContainer>{referral.patient.phoneWork}</TextContainer>
              <SubTitle>Adress</SubTitle>
              <TextContainer>
                {referral.patient.address
                  ? referral.patient.address +
                    ", " +
                    referral.patient.postalCode +
                    ", " +
                    referral.patient.city
                  : ""}
              </TextContainer>
              <SubTitle>Medicinsk information</SubTitle>
              <TextContainer>{referral.patient.medicalInfo}</TextContainer>
            </div>
          </Box>
        </FlexGrow>
        <FlexGrow>
          <Title>Remittent</Title>
          <Box>
            <SubTitle>Namn</SubTitle>
            <TextContainer>{referral.sender.name}</TextContainer>
            <SubTitle>Kombika</SubTitle>
            <TextContainer>{referral.sender.id}</TextContainer>
            <SubTitle>Fakturamottagare</SubTitle>
            <TextContainer>{referral.invoiceRecipientId}</TextContainer>
            <SubTitle>Tel</SubTitle>
            <TextContainer>{referral.sender.phone}</TextContainer>
            <SubTitle>Organisation</SubTitle>
            <TextContainer>{referral.sender.organization}</TextContainer>
            <SubTitle>Adress</SubTitle>
            <TextContainer>
              {referral.sender.address}
              {referral.sender.postalCode
                ? ", " + referral.sender.postalCode
                : ""}
              {referral.sender.city ? ", " + referral.sender.city : ""}
            </TextContainer>
          </Box>
        </FlexGrow>
        <FlexGrow>
          <div>
            <Title>Akut</Title>
            <UrgentBox
              onClick={() =>
                !readOnly && dispatch(setReferralUrgent(!referral.urgent))
              }
            >
              {referral.urgent ? <UrgentSymbol /> : null}
            </UrgentBox>
          </div>
          <Ruler />
          <div style={{ marginTop: 20 }}>
            <Link
              onClick={() =>
                HttpClient.setCurrentPatientInWebdoc(
                  referral.patient.id,
                  (err) => {
                    if (err.code === 105) {
                      dispatch(
                        displayError(
                          "Patienten har ingen journal, den skapas automatiskt vid granskning"
                        )
                      );
                    } else {
                      dispatch(
                        displayError(
                          "Det gick inte att öppna patientens journal"
                        )
                      );
                    }
                  }
                )
              }
            >
              Öppna journal
            </Link>
          </div>
        </FlexGrow>
      </FlexRow>
      <Ruler />
      <FlexRow>
        <FlexGrow>
          <Title>Från remittent</Title>
          <Box>
            <SubTitle>Anamnes, Status</SubTitle>
            <TextContainer>{referral.anamnesis}</TextContainer>
            <SubTitle>Frågeställning</SubTitle>
            <TextContainer>{referral.diagnosis}</TextContainer>
            <SubTitle>Kommentar</SubTitle>
            <TextContainer>{referral.comment}</TextContainer>
            <SubTitle>Tidigare undersökningar</SubTitle>
            <TextContainer>{referral.previousExams}</TextContainer>
            <Box>
              <SubTitle>Önskade undersökningar</SubTitle>
              <TextContainer>{referral.requestedExaminations}</TextContainer>
            </Box>
          </Box>
        </FlexGrow>

        <FlexGrow>
          <Title>Anteckningar</Title>
          <Box>
            <NotePad
              disabled={readOnly}
              value={referral.note ? referral.note : ""}
              onChange={(e) => dispatch(setReferralNote(e.currentTarget.value))}
            />
          </Box>
        </FlexGrow>
        <FlexGrow>
          <Title>Undersökningar</Title>
          <Box>
            <TextContainer>
              {referral.products.map((p) => (
                <ExaminationRow key={p.code}>
                  {p.name}
                  <RemoveExaminationButton
                    onClick={() =>
                      !readOnly && dispatch(removeReferralProduct(p))
                    }
                    disabled={readOnly}
                  >
                    X
                  </RemoveExaminationButton>
                </ExaminationRow>
              ))}
            </TextContainer>
            <Select
              onChange={(product) =>
                setSelectedProduct(product ? product.value : null)
              }
              options={selectableProducts}
              isDisabled={readOnly}
              placeholder={"Lägg till undersökning"}
            />
            <Button onClick={() => addProduct()} disabled={!selectedProduct}>
              Lägg till
            </Button>
          </Box>
          <Title>Status</Title>
          <Box>
            <TextContainer>{stateDisplay(referral.state)}</TextContainer>
          </Box>
          <Title>Klinik</Title>
          <Box>
            <Select
              onChange={(clinic) => {
                if (clinic) {
                  if (clinic.label && clinic.value) {
                    dispatch(
                      setReferralClinic({
                        id: clinic.value,
                        name: clinic.label,
                      })
                    );
                  }
                }
              }}
              isDisabled={readOnly}
              value={selectableClinics.find(
                (clinic) => clinic.value === referral.clinicId
              )}
              options={selectableClinics}
              placeholder={"Välj klinik"}
            />
          </Box>
          <BlockButton
            disabled={!dirty || readOnly}
            onClick={() => {
              dispatch(updateReferral(referral));
              dispatch(clearDirty());
            }}
          >
            Spara utkast
          </BlockButton>
          <BlockButton
            disabled={
              !referral.clinicId || readOnly || referral.products.length < 1
            }
            onClick={async () => {
              await dispatch(setReferralState(State.VERIFIED));
              dispatch(updateReferral({ ...referral, state: State.VERIFIED }));
              dispatch(clearDirty());
            }}
          >
            Granskad
          </BlockButton>
          {referral.state === State.NEW && (
            <BlockButton
              disabled={readOnly}
              onClick={() => {
                dispatch(setReferralState(State.NEED_SECOND_VERIFICATION));
                dispatch(
                  updateReferral({
                    ...referral,
                    state: State.NEED_SECOND_VERIFICATION,
                  })
                );
                dispatch(clearDirty());
              }}
            >
              2:a granskning
            </BlockButton>
          )}

          <ReturnButton
            onClick={() => dispatch(setReturnStatus(true))}
            disabled={readOnly}
          >
            Skapa remissretur
          </ReturnButton>

          <CancelButton onClick={() => dispatch(clearDraftReferral())}>
            {dirty ? "Avbryt" : "Stäng"}
          </CancelButton>
        </FlexGrow>
      </FlexRow>
    </Modal>
  );
};

const stateDisplay = (state: State): string => {
  switch (state) {
    case State.NEW:
      return "Ogranskad";
    case State.NEED_SECOND_VERIFICATION:
      return "Behöver andra granskning";
    case State.VERIFIED:
      return "Granskad";
    case State.RETURNED:
      return "Returnerad";
  }
};
