import { configureStore } from "@reduxjs/toolkit";
import { ProductSlice, ProductState } from "./features/products/product.slice";
import {
  ReferralSlice,
  ReferralState,
} from "./features/referrals/referral.slice";
import {
  ReferralEditor,
  ReferralEditorState,
} from "./features/referrals/referral-editor.slice";
import { ErrorSlice, ErrorState } from "./features/error-drawer/error.slice";
import {
  ExaminationSlice,
  ExaminationState,
} from "./features/examination/examination.slice";
import { AdminSlice, AdminState } from "./features/admin/admin.slice";
import {
  AddExaminationSlice,
  AddExaminationState,
} from "./features/examination/add-examination/add-examination.slice";
import {
  ExaminationListState,
  ExaminationsListSlice,
} from "./features/examination/examinations-list/examinations-list.slice";
import {
  ExaminationEditorSlice,
  ExaminationEditorState,
} from "./features/examination/examination-editor.slice";
import {
  ReferralListSlice,
  ReferralListState,
} from "./features/referral-lists/referral-list.slice";

export interface RootState {
  product: ProductState;
  referral: ReferralState;
  examination: ExaminationState;
  examinationEditor: ExaminationEditorState;
  examinationsList: ExaminationListState;
  addExamination: AddExaminationState;
  referralEditor: ReferralEditorState;
  referralList: ReferralListState;
  admin: AdminState;
  error: ErrorState;
}

export default configureStore({
  reducer: {
    product: ProductSlice.reducer,
    referral: ReferralSlice.reducer,
    examination: ExaminationSlice.reducer,
    examinationEditor: ExaminationEditorSlice.reducer,
    examinationsList: ExaminationsListSlice.reducer,
    addExamination: AddExaminationSlice.reducer,
    referralEditor: ReferralEditor.reducer,
    referralList: ReferralListSlice.reducer,
    admin: AdminSlice.reducer,
    error: ErrorSlice.reducer,
  },
});
