import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Examination, Keyword, Record, Visit } from "./examination";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";
import { setAnswerForQuestionId } from "../../common/common";

export interface ExaminationEditorState {
  examination?: Examination;
  selectedVisit?: Visit;
  availableVisits: Visit[];
  availableVisitsLoading: boolean;
  loading: boolean;
  isDirty: boolean;
  isPreview: boolean;
}

export interface QuestionResponse {
  id: number;
  value: string;
}

export const ExaminationEditorSlice = createSlice({
  name: "examination-editor",
  initialState: {
    availableVisits: [],
    availableVisitsLoading: false,
    loading: false,
    isDirty: false,
    isPreview: false,
  } as ExaminationEditorState,
  reducers: {
    setExaminationLoading: (state) => {
      state.loading = true;
    },
    setSelectedExamination: (
      state,
      action: PayloadAction<Examination | undefined>
    ) => {
      state.loading = false;
      if (state.examination?.id !== action.payload?.id) {
        state.selectedVisit = undefined;
      }
      state.isDirty = false;
      state.isPreview = false;
      state.examination = action.payload;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    setAvailableVisitsLoading: (state, action: PayloadAction<boolean>) => {
      state.availableVisitsLoading = action.payload;
    },
    setAvailableVisits: (state, action: PayloadAction<Visit[]>) => {
      state.availableVisits = action.payload;
    },
    setSelectedVisit: (state, action: PayloadAction<Visit | undefined>) => {
      state.selectedVisit = action.payload;
    },
    setQuestionValue: (state, action: PayloadAction<QuestionResponse>) => {
      if (state.examination) {
        state.isDirty = true;
        state.examination.record = setAnswerForQuestionId(
          action.payload.id,
          action.payload.value,
          state.examination.record
        );
      }
    },
    setIsPreview: (state, action: PayloadAction<boolean>) => {
      state.isPreview = action.payload;
    },
  },
});

export const getExamination =
  (examinationId: number, patientId: string) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(ExaminationEditorSlice.actions.setExaminationLoading());
    dispatch(ExaminationEditorSlice.actions.setAvailableVisits([]));
    dispatch(setSelectedExamination(undefined));
    const examination = await HttpClient.getExaminationById(
      examinationId,
      patientId,
      (error) => {
        dispatch(displayError(`Could not fetch examinations: ${error}`));
      }
    );
    dispatch(
      ExaminationEditorSlice.actions.setSelectedExamination(examination)
    );
    if (!examination.saved) {
      dispatch(getAvailableVisits(examinationId));
    }
  };

export const getAvailableVisits =
  (examinationId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(ExaminationEditorSlice.actions.setAvailableVisitsLoading(true));
    dispatch(ExaminationEditorSlice.actions.setAvailableVisits([]));
    dispatch(ExaminationEditorSlice.actions.setSelectedVisit(undefined));

    const availableVisits = await HttpClient.getVisitsForExamination(
      examinationId,
      (error) => {
        dispatch(displayError(`Could not fetch clinics: ${error}`));
      }
    );
    dispatch(
      ExaminationEditorSlice.actions.setAvailableVisits(availableVisits)
    );
    dispatch(ExaminationEditorSlice.actions.setAvailableVisitsLoading(false));
  };

const updateExamination = async (
  updatedExamination: Examination,
  visitId: string | undefined,
  clinicId: string | undefined,
  visitTime: string | undefined,
  final: boolean,
  dispatch: Dispatch<any>
) => {
  const savedExamination = await HttpClient.updateExamination(
    updatedExamination.id,
    visitId,
    clinicId,
    visitTime,
    updatedExamination.record,
    updatedExamination.isReturnExamination,
    final,
    (error) => {
      dispatch(displayError(`Could not update examination: ${error}`));
    }
  );
  dispatch(ExaminationEditorSlice.actions.setIsDirty(false));
  dispatch(ExaminationEditorSlice.actions.setIsPreview(false));
  return savedExamination;
};

export const updateExaminationAndEnterPreview =
  (updatedExamination: Examination, visit: Visit | undefined) =>
  async (dispatch: Dispatch<any>) => {
    const savedExamination = await updateExamination(
      updatedExamination,
      visit?.id,
      visit?.clinicId,
      visit?.date,
      false,
      dispatch
    );
    dispatch(setSelectedExamination(savedExamination));
    dispatch(setIsPreview(true));
  };

export const saveDraftExamination =
  (updatedExamination: Examination, visit: Visit | undefined) =>
  async (dispatch: Dispatch<any>) => {
    await updateExamination(
      updatedExamination,
      visit?.id,
      visit?.clinicId,
      visit?.date,
      false,
      dispatch
    );
  };

export const saveFinalExamination =
  (updatedExamination: Examination, visit: Visit | undefined) =>
  async (dispatch: Dispatch<any>) => {
    const savedExamination = await updateExamination(
      updatedExamination,
      visit?.id,
      visit?.clinicId,
      visit?.date,
      true,
      dispatch
    );
    dispatch(setSelectedExamination(savedExamination));
  };

export const deleteExamination =
  (examination: Examination) => async (dispatch: Dispatch<any>) => {
    const success = await HttpClient.deleteExamination(
      examination.id,
      (error) => {
        dispatch(displayError(`Could not delete examination: ${error}`));
      }
    );

    if (success) {
      dispatch(setSelectedExamination(undefined));
    }
  };

export const convertExaminationToReturnExamination =
  (examination: Examination) => async (dispatch: Dispatch<any>) => {
    const returnRecordTemplate = await HttpClient.getReturnRecordTemplate(
      (error) => {
        dispatch(
          displayError(`Could not fetch return record template: ${error}`)
        );
      }
    );

    if (returnRecordTemplate) {
      const newExamination = {
        ...examination,
        isReturnExamination: true,
        visitId: undefined,
        visitTime: undefined,
        record: returnRecordTemplate,
      };
      dispatch(setSelectedExamination(newExamination));
      dispatch(saveDraftExamination(newExamination, undefined));
      dispatch(setSelectedVisit(undefined));
    }
  };

export const createNewVersionOfExamination =
  (examination: Examination) => async (dispatch: Dispatch<any>) => {
    const savedExamination = await HttpClient.createNewVersionOfExamination(
      examination.id,
      (error) => {
        dispatch(
          displayError(`Could not create new version of examination: ${error}`)
        );
      }
    );
    dispatch(
      ExaminationEditorSlice.actions.setSelectedExamination(savedExamination)
    );
    dispatch(getAvailableVisits(savedExamination.id));
    dispatch(ExaminationEditorSlice.actions.setIsDirty(false));
  };

export const {
  setSelectedExamination,
  setSelectedVisit,
  setQuestionValue,
  setIsPreview,
  setExaminationLoading,
} = ExaminationEditorSlice.actions;

export default ExaminationEditorSlice.reducer;
