import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  getConfiguration,
  setDraftConfiguration,
  updateConfiguration,
} from "./admin.slice";
import { Input, QuestionTitle } from "../../components/form-components";
import Select from "react-select";
import styled from "styled-components";
import { Button } from "../../components/buttons";
import { Record, RecordType } from "../examination/examination";
import { Product } from "../products/product";

const FullWidthInput = styled(Input)`
  width: 95%;
`;

const Wrapper = styled.div`
  padding: 10px;
`;

const FullWidthTable = styled.table`
  width: 100%;
`;

const Section = styled.table`
  padding-left: 10px;
`;

const SectionTitle = styled.div`
  margin-top: 10px;
  font-weight: 600;
`;

interface recordTemplateChangeEvent {
  (template: Record | undefined): void;
}

interface productChangeEvent {
  (product: Product): void;
}

export const Admin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  const {
    configuration,
    draftConfiguration,
    availableClinics,
    availableUsers,
    availablePatientTypes,
    recordTemplates,
    isDirty,
  } = useSelector((state: RootState) => ({
    availableClinics: state.admin.availableClinics,
    availableUsers: state.admin.availableUsers,
    availablePatientTypes: state.admin.availablePatientTypes,
    recordTemplates: state.admin.recordTemplates,
    configuration: state.admin.configuration,
    draftConfiguration: state.admin.draftConfiguration,
    isDirty: state.admin.isDirty,
  }));

  if (!configuration || !draftConfiguration || !recordTemplates) {
    return null;
  }

  const selectablePatientTypes = availablePatientTypes.map((patientType) => ({
    label: patientType.name,
    value: patientType.id,
  }));

  const selectableUsers = availableUsers.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  const selectableProducts = configuration.products.map((product) => ({
    label: `${product.name}`,
    value: product.code,
  }));

  return (
    <Wrapper>
      <QuestionTitle>Enheter som remisser kan sparas till</QuestionTitle>
      <Select
        value={draftConfiguration.referralEnabledClinics.map((clinic) => ({
          label: clinic.name,
          value: clinic.id,
        }))}
        options={availableClinics.map((clinic) => ({
          label: clinic.name,
          value: clinic.id,
        }))}
        onChange={(event) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              referralEnabledClinics: event.map((clinic) => ({
                id: clinic.value,
                name: clinic.label,
              })),
            })
          );
        }}
        isMulti
        placeholder={""}
      />

      {draftConfiguration.referralEnabledClinics.map((clinic) => (
        <>
          <SectionTitle>{clinic.name}</SectionTitle>
          <Section>
            <QuestionTitle>
              Välj vilken användare som ska remisser ska skapas under
            </QuestionTitle>
            <Select
              value={selectableUsers.find(
                (option) =>
                  option.value ===
                  draftConfiguration?.clinicReferralUserIds[clinic.id]
              )}
              options={selectableUsers}
              onChange={(event) => {
                if (event) {
                  dispatch(
                    setDraftConfiguration({
                      ...draftConfiguration,
                      clinicReferralUserIds: {
                        ...draftConfiguration?.clinicReferralUserIds,
                        [clinic.id]: event.value,
                      },
                    })
                  );
                }
              }}
              placeholder={""}
            />
          </Section>
        </>
      ))}

      <QuestionTitle>Enheter som remiss-svar kan sparas till</QuestionTitle>
      <Select
        value={draftConfiguration.responseEnabledClinics.map((clinic) => ({
          label: clinic.name,
          value: clinic.id,
        }))}
        options={availableClinics.map((clinic) => ({
          label: clinic.name,
          value: clinic.id,
        }))}
        onChange={(event) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              responseEnabledClinics: event.map((clinic) => ({
                id: clinic.value,
                name: clinic.label,
              })),
            })
          );
        }}
        isMulti
        placeholder={""}
      />

      <QuestionTitle>Standardmall för remisser</QuestionTitle>
      <TemplateSelector
        selectedId={draftConfiguration.defaultReferralTemplateId}
        recordTemplates={recordTemplates.filter(
          (template) => template.type === RecordType.NOTE
        )}
        onChange={(template) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              defaultReferralTemplateId:
                template && template.id ? template.id : null,
            })
          );
        }}
      />

      <QuestionTitle>Standardmall för remissvar</QuestionTitle>
      <TemplateSelector
        selectedId={draftConfiguration.defaultResponseTemplateId}
        recordTemplates={recordTemplates.filter(
          (template) => template.type === RecordType.NOTE
        )}
        onChange={(template) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              defaultResponseTemplateId:
                template && template.id ? template.id : null,
            })
          );
        }}
      />

      <QuestionTitle>
        Välj vilken patienttyp som ska användas för nya patienter och
        anteckningar
      </QuestionTitle>
      <Select
        value={selectablePatientTypes.find(
          (option) => option.value === draftConfiguration?.patientTypeId
        )}
        options={selectablePatientTypes}
        onChange={(event) => {
          if (event) {
            dispatch(
              setDraftConfiguration({
                ...draftConfiguration,
                patientTypeId: event.value,
              })
            );
          }
        }}
        placeholder={""}
      />

      <QuestionTitle>Undersökning för remiss-returer</QuestionTitle>
      <Select
        value={selectableProducts.find(
          (product) =>
            product.value === draftConfiguration?.returnResponseProductId
        )}
        options={selectableProducts}
        onChange={(event) => {
          if (event) {
            dispatch(
              setDraftConfiguration({
                ...draftConfiguration,
                returnResponseProductId: event.value,
              })
            );
          }
        }}
        placeholder={""}
      />

      <QuestionTitle>Namn på avsändare av remiss-returer</QuestionTitle>
      <Input
        type="text"
        value={draftConfiguration.returnerName}
        onChange={(e) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              returnerName: e.target.value,
            })
          );
        }}
      />

      <QuestionTitle>
        Namn på avsändande organisation för remiss-returer
      </QuestionTitle>
      <Input
        type="text"
        value={draftConfiguration.returnerOrganization}
        onChange={(e) => {
          dispatch(
            setDraftConfiguration({
              ...draftConfiguration,
              returnerOrganization: e.target.value,
            })
          );
        }}
      />

      <QuestionTitle>Undersökningar</QuestionTitle>
      <FullWidthTable>
        <thead>
          <tr>
            <th>Id</th>
            <th>Namn</th>
            <th>Kategori</th>
            <th>Journalmall för remiss</th>
            <th>Journalmall för remissvar</th>
            <th>Dold</th>
          </tr>
        </thead>
        <tbody>
          {draftConfiguration.products.map((product) => (
            <ProductRow
              key={product.code}
              recordTemplates={recordTemplates}
              onChange={(product) => {
                const idx = draftConfiguration?.products.findIndex(
                  (p) => p.code === product.code
                );
                const products = [...draftConfiguration?.products];
                products[idx] = product;
                dispatch(
                  setDraftConfiguration({
                    ...draftConfiguration,
                    products,
                  })
                );
              }}
              product={product}
            />
          ))}
        </tbody>
      </FullWidthTable>
      <Button
        disabled={!isDirty}
        onClick={() =>
          isDirty && dispatch(updateConfiguration(draftConfiguration))
        }
      >
        Spara
      </Button>
    </Wrapper>
  );
};

const TemplateSelector = ({
  selectedId,
  onChange,
  recordTemplates,
}: {
  selectedId: number | null | undefined;
  onChange: recordTemplateChangeEvent;
  recordTemplates: Record[];
}) => {
  const selectedTemplate = recordTemplates.find(
    (template) => template.id === selectedId
  );

  return (
    <Select
      value={
        selectedTemplate
          ? {
              value: selectedTemplate.id,
              label: selectedTemplate.title,
            }
          : null
      }
      onChange={(e) =>
        onChange(recordTemplates.find((template) => template.id === e?.value))
      }
      options={recordTemplates.map((template) => ({
        label: template.title,
        value: template.id,
      }))}
      placeholder={""}
    />
  );
};

const ProductRow = ({
  product,
  recordTemplates,
  onChange,
}: {
  product: Product;
  recordTemplates: Record[];
  onChange: productChangeEvent;
}) => {
  return (
    <tr>
      <td>{product.code}</td>
      <td>
        <FullWidthInput
          value={product.name}
          onChange={(event) =>
            onChange({
              ...product,
              name: event.target.value,
            })
          }
        />
      </td>
      <td>
        <FullWidthInput
          value={product.category}
          onChange={(event) =>
            onChange({
              ...product,
              category: event.target.value,
            })
          }
        />
      </td>
      <td>
        <TemplateSelector
          selectedId={product.referralTemplateId}
          onChange={(template) => {
            onChange({
              ...product,
              referralTemplateId: template?.id,
            });
          }}
          recordTemplates={recordTemplates.filter(
            (template) => template.type === RecordType.NOTE
          )}
        />
      </td>
      <td>
        <TemplateSelector
          selectedId={product.responseTemplateId}
          onChange={(template) => {
            onChange({
              ...product,
              responseTemplateId: template?.id,
            });
          }}
          recordTemplates={recordTemplates.filter(
            (template) => template.type === RecordType.NOTE
          )}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={product.hidden}
          onChange={(e) =>
            onChange({
              ...product,
              hidden: e.target.checked,
            })
          }
        />
      </td>
    </tr>
  );
};
