import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Referral, State } from "./referral";
import { Product } from "../products/product";
import { Clinic } from "../admin/admin";
import { KeywordType, Record, RecordType } from "../examination/examination";
import { setAnswerForQuestionId } from "../../common/common";
import { Dispatch } from "react";
import { HttpClient } from "../../http-client/http-client";
import { displayError } from "../error-drawer/error.slice";

interface QuestionResponse {
  id: number;
  value: string;
}

export interface ReferralEditorState {
  referral: Referral | null;
  isDirty: boolean;
  isReturning: boolean;
  returnRecord: Record | null;
}

export const ReferralEditor = createSlice({
  name: "referral-editor",
  initialState: {
    referral: null,
    isDirty: false,
    isReturning: false,
    returnRecord: null,
  } as ReferralEditorState,
  reducers: {
    setDraftReferral: (state, action: PayloadAction<Referral>) => {
      state.referral = action.payload;
      state.isDirty = false;
      state.isReturning = false;
      state.returnRecord = null;
    },
    clearDraftReferral: (state) => {
      state.referral = null;
      state.isDirty = false;
    },
    clearDirty: (state) => {
      state.isDirty = false;
    },
    setReferralState: (state, action: PayloadAction<State>) => {
      if (state.referral) {
        state.referral.state = action.payload;
        state.isDirty = true;
      }
    },
    setReferralNote: (state, action: PayloadAction<string>) => {
      if (state.referral) {
        state.referral.note = action.payload;
        state.isDirty = true;
      }
    },
    setReferralUrgent: (state, action: PayloadAction<boolean>) => {
      if (state.referral) {
        state.referral.urgent = action.payload;
        state.isDirty = true;
      }
    },
    setReferralClinic: (state, action: PayloadAction<Clinic>) => {
      if (state.referral) {
        state.referral.clinicId = action.payload.id;
        state.referral.clinicName = action.payload.name;
        state.isDirty = true;
      }
    },
    setReferralClinicWithoutMakingReferralDirty: (
      state,
      action: PayloadAction<Clinic>
    ) => {
      if (state.referral) {
        state.referral.clinicId = action.payload.id;
        state.referral.clinicName = action.payload.name;
      }
    },
    addReferralProduct: (state, action: PayloadAction<Product>) => {
      if (state.referral) {
        state.referral.products.push(action.payload);
        state.isDirty = true;
      }
    },
    removeReferralProduct: (state, action: PayloadAction<Product>) => {
      if (state.referral) {
        const idx = state.referral.products.findIndex(
          (product) => product.code === action.payload.code
        );
        if (idx >= 0) {
          state.referral.products.splice(idx, 1);
          state.isDirty = true;
        }
      }
    },
    setIsReturning: (state, action: PayloadAction<boolean>) => {
      state.isReturning = action.payload;
    },
    setReturnRecord: (state, action: PayloadAction<Record | null>) => {
      state.returnRecord = action.payload;
    },
    setQuestionValue: (state, action: PayloadAction<QuestionResponse>) => {
      if (state.returnRecord) {
        state.isDirty = true;
        state.returnRecord = setAnswerForQuestionId(
          action.payload.id,
          action.payload.value,
          state.returnRecord
        );
      }
    },
  },
});

export const {
  setDraftReferral,
  clearDraftReferral,
  clearDirty,
  setReferralUrgent,
  setReferralNote,
  setReferralState,
  setReferralClinic,
  setReferralClinicWithoutMakingReferralDirty,
  addReferralProduct,
  setQuestionValue,
  removeReferralProduct,
} = ReferralEditor.actions;

export default ReferralEditor.reducer;

export const setReturnStatus =
  (isReturning: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(ReferralEditor.actions.setIsReturning(isReturning));
    if (isReturning) {
      const record = await HttpClient.getReturnRecordTemplate(() => {
        dispatch(displayError(`Could not fetch return record template`));
      });
      dispatch(ReferralEditor.actions.setReturnRecord(record));
    } else {
      dispatch(ReferralEditor.actions.setReturnRecord(null));
    }
  };
