import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createExaminationForProductOnReferral,
  getReferralsForPatient,
  setIncomplete,
} from "./add-examination.slice";
import Select from "react-select";
import { QuestionTitle, Title } from "../../../components/form-components";
import { Product } from "../../products/product";
import { Referral } from "../../referrals/referral";
import { Button } from "../../../components/buttons";
import { useHistory } from "react-router-dom";
import { ReferralMiniViewer } from "../../../components/referral-mini-viewer";

const ContentWrapper = styled.div`
  padding: 20px;
  margin-bottom: 300px;
`;

const ReferralMiniViewerBox = styled.div`
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: white;
`;

export const AddExaminationComponent = ({
  patientId,
}: {
  patientId: string;
}) => {
  const { loading, referrals, products, complete } = useSelector(
    (state: RootState) => ({
      loading: state.examination.loading,
      referrals: state.addExamination.referrals,
      products: state.product.products,
      complete: state.addExamination.complete,
    })
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (complete) {
      dispatch(setIncomplete());
      history.goBack();
    }
  }, [dispatch, history, complete]);

  useEffect(() => {
    dispatch(getReferralsForPatient(patientId));
  }, [dispatch, patientId]);

  const [selectedReferral, setSelectedReferral] = useState<Referral | null>(
    null
  );

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  return (
    <ContentWrapper>
      <Title>Lägg till ny undersökning till remiss</Title>
      <QuestionTitle>Välj remiss</QuestionTitle>
      <Select
        options={referrals.map((referral) => ({
          label: `${referral.orderTime.toString().substring(0, 10)} - ${
            referral.sender.name
          }, ${referral.sender.organization}`,
          value: referral,
        }))}
        onChange={(option) => setSelectedReferral(option ? option.value : null)}
        noOptionsMessage={() => "Inga remisser hittade"}
        placeholder={""}
        isLoading={loading}
      />

      {selectedReferral && (
        <>
          <ReferralMiniViewerBox>
            <ReferralMiniViewer referral={selectedReferral} />
          </ReferralMiniViewerBox>

          <QuestionTitle>Välj undersökning att lägga till</QuestionTitle>
          <Select
            isDisabled={!selectedReferral}
            options={products.map((product) => ({
              label: product.name,
              value: product,
            }))}
            onChange={(option) =>
              setSelectedProduct(option ? option.value : null)
            }
            placeholder={""}
            isLoading={loading}
          />
        </>
      )}

      <Button
        onClick={() => {
          if (!selectedReferral || !selectedProduct) {
            return;
          }

          dispatch(
            createExaminationForProductOnReferral(
              selectedReferral.id,
              selectedProduct.code
            )
          );
        }}
        disabled={!selectedProduct}
      >
        Spara
      </Button>
      <Button
        onClick={() => {
          history.goBack();
        }}
      >
        Avbryt
      </Button>
    </ContentWrapper>
  );
};
