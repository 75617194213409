import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import { ErrorDrawerContainer } from "./features/error-drawer/error-drawer";
import { KeepAlive } from "./features/keep-alive/keep-alive";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { fetchProducts } from "./features/products/product.slice";
import { ExaminationEditorComponent } from "./features/examination/examination-editor.component";
import { AddExaminationComponent } from "./features/examination/add-examination/add-examination.component";
import { Admin } from "./features/admin/admin.component";
import { TabNavigation } from "./features/referral-lists/tab-navigation.component";
import { ExaminationsListComponent } from "./features/examination/examinations-list/examinations-list.component";

store.dispatch(fetchProducts());

function App() {
  return (
    <Provider store={store}>
      <KeepAlive />
      <ErrorDrawerContainer />
      <BrowserRouter>
        <Switch>
          <Route
            path="/patient/:patientId/examinations/new"
            render={({ match }) => (
              <AddExaminationComponent patientId={match.params.patientId} />
            )}
          />
          <Route
            path="/patient/:patientId/examinations/:examinationId"
            render={({ match }) => (
              <ExaminationEditorComponent
                patientId={match.params.patientId}
                examinationId={parseInt(match.params.examinationId, 10)}
              />
            )}
          />
          <Route
            path="/patient/:patientId/examinations"
            render={({ match }) => (
              <ExaminationsListComponent patientId={match.params.patientId} />
            )}
          />
          <Route path="/referrals">
            <TabNavigation />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
