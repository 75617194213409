import { Product } from "../features/products/product";
import {
  Referral,
  State,
  UpdateReferral,
} from "../features/referrals/referral";
import {
  Examination,
  Record,
  Visit,
} from "../features/examination/examination";
import { Clinic, Configuration, PatientType } from "../features/admin/admin";
import { Page } from "../common/common";
import { IFilter } from "../components/filters";

interface ErrorHandler {
  (error: any): void;
}

const handleError = async (response: Response, handler: ErrorHandler) => {
  const message = await response.text();
  try {
    const payload = JSON.parse(message);
    if (payload.code) {
      handler(payload);
    } else if (payload.message) {
      handler(payload.message);
    }
  } catch (e) {
    handler(message);
  }
};

export const HttpClient = {
  async getProducts(errorHandler: ErrorHandler): Promise<Product[]> {
    const response = await fetch("/api/product");
    if (response.ok) {
      return (await response.json()) as Product[];
    } else {
      await handleError(response, errorHandler);
      return [];
    }
  },

  async getReferrals(
    offset: number,
    pageSize: number,
    orderBy: string,
    orderDirection: "DESC" | "ASC",
    state: State,
    filters: IFilter[],
    errorHandler: ErrorHandler
  ): Promise<Page<Referral>> {
    const response = await fetch(
      `/api/referral?state=${state}&offset=${offset}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}&filters=${filters
        .map((f) => f.type + ":" + f.value)
        .join(",")}`
    );
    if (response.ok) {
      return (await response.json()) as Page<Referral>;
    } else {
      await handleError(response, errorHandler);
      return {
        count: 0,
        offset: offset,
        pageSize: pageSize,
        data: [],
      };
    }
  },

  async getReferral(
    referralId: number,
    errorHandler: ErrorHandler
  ): Promise<Referral | undefined> {
    const response = await fetch(`/api/referral/${referralId}`);
    if (response.ok) {
      return (await response.json()) as Referral;
    } else {
      await handleError(response, errorHandler);
    }
  },

  async getReferralsForPatient(
    patientId: string,
    referralState: State,
    errorHandler: ErrorHandler
  ): Promise<Referral[]> {
    const response = await fetch(
      `/api/referral/patient/${patientId}?state=${referralState}`
    );
    if (response.ok) {
      return (await response.json()) as Referral[];
    } else {
      await handleError(response, errorHandler);
      return [];
    }
  },

  async createExamination(
    referralId: number,
    productId: string,
    errorHandler: ErrorHandler
  ): Promise<boolean> {
    const response = await fetch(`/api/examination`, {
      body: JSON.stringify({
        referralId,
        productId,
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return true;
    } else {
      await handleError(response, errorHandler);
      return false;
    }
  },

  async updateReferral(
    id: number,
    referral: UpdateReferral,
    errorHandler: ErrorHandler
  ): Promise<Referral | undefined> {
    const response = await fetch(`/api/referral/${id}`, {
      body: JSON.stringify(referral),
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return (await response.json()) as Referral;
    } else {
      await handleError(response, errorHandler);
    }
  },

  async getExaminations(
    patientId: string,
    errorHandler: ErrorHandler
  ): Promise<Examination[]> {
    const response = await fetch(`/api/examination/patient/${patientId}`);
    if (response.ok) {
      return (await response.json()) as Examination[];
    } else {
      await handleError(response, errorHandler);
      return [];
    }
  },

  async getExaminationById(
    examinationId: number,
    patientId: string,
    errorHandler: ErrorHandler
  ): Promise<Examination> {
    const response = await fetch(
      `/api/examination/patient/${patientId}/${examinationId}`
    );
    if (response.ok) {
      return (await response.json()) as Examination;
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch examination");
    }
  },

  async getExaminationsWithState(
    saved: boolean,
    offset: number,
    pageSize: number,
    orderBy: string,
    orderDirection: "DESC" | "ASC",
    filters: IFilter[],
    errorHandler: ErrorHandler
  ): Promise<Page<Examination>> {
    const response = await fetch(
      `/api/examination?saved=${saved}&offset=${offset}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}&filters=${filters
        .map((f) => f.type + ":" + f.value)
        .join(",")}`
    );
    if (response.ok) {
      return (await response.json()) as Page<Examination>;
    } else {
      await handleError(response, errorHandler);
      return {
        count: 0,
        pageSize: pageSize,
        offset: offset,
        data: [],
      };
    }
  },

  async getConfiguration(errorHandler: ErrorHandler): Promise<Configuration> {
    const response = await fetch("/api/configuration");
    if (response.ok) {
      return (await response.json()) as Configuration;
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch configuration");
    }
  },

  async saveConfiguration(
    configuration: Configuration,
    errorHandler: ErrorHandler
  ): Promise<Configuration> {
    const response = await fetch(`/api/configuration`, {
      body: JSON.stringify(configuration),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return (await response.json()) as Configuration;
    } else {
      await handleError(response, errorHandler);
      throw Error(`Could not save configuration`);
    }
  },

  async getAllClinics(errorHandler: ErrorHandler): Promise<Clinic[]> {
    const response = await fetch("/api/configuration/clinics");
    if (response.ok) {
      return (await response.json()) as Clinic[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch clinics");
    }
  },

  async getVisitsForExamination(
    examinationId: number,
    errorHandler: ErrorHandler
  ): Promise<Visit[]> {
    const response = await fetch(
      "/api/examination/" + examinationId + "/visits"
    );
    if (response.ok) {
      return (await response.json()) as Visit[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch visits");
    }
  },

  async getReferralClinics(errorHandler: ErrorHandler): Promise<Clinic[]> {
    const response = await fetch("/api/configuration/enabledReferralClinics");
    if (response.ok) {
      return (await response.json()) as Clinic[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch clinics");
    }
  },

  async getResponseClinics(errorHandler: ErrorHandler): Promise<Clinic[]> {
    const response = await fetch("/api/configuration/enabledResponseClinics");
    if (response.ok) {
      return (await response.json()) as Clinic[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch clinics");
    }
  },

  async getPatientTypes(errorHandler: ErrorHandler): Promise<PatientType[]> {
    const response = await fetch("/api/configuration/patientTypes");
    if (response.ok) {
      return (await response.json()) as PatientType[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch record templates");
    }
  },

  async getUsers(errorHandler: ErrorHandler): Promise<any[]> {
    const response = await fetch("/api/configuration/users");
    if (response.ok) {
      return (await response.json()) as any[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch record templates");
    }
  },

  async getRecordTemplates(errorHandler: ErrorHandler): Promise<Record[]> {
    const response = await fetch("/api/configuration/recordTemplates");
    if (response.ok) {
      return (await response.json()) as Record[];
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch record templates");
    }
  },

  async updateExamination(
    id: number,
    visitId: string | undefined,
    clinicId: string | undefined,
    visitTime: string | undefined,
    record: any,
    isReturnExamination: boolean,
    saved: boolean,
    errorHandler: ErrorHandler
  ): Promise<Examination> {
    const body: {
      visitId: string | null;
      clinicId: string | undefined;
      visitTime: string | null;
      record: any;
      saved: boolean;
      isReturnExamination: boolean;
    } = {
      record,
      visitId: visitId ? visitId : null,
      visitTime: visitTime ? visitTime : null,
      clinicId,
      saved,
      isReturnExamination,
    };
    const response = await fetch(`/api/examination/${id}`, {
      body: JSON.stringify(body),
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return (await response.json()) as Examination;
    } else {
      await handleError(response, errorHandler);
      throw Error(`Could not save examination`);
    }
  },

  async createNewVersionOfExamination(
    id: number,
    errorHandler: ErrorHandler
  ): Promise<Examination> {
    const response = await fetch(`/api/examination/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return (await response.json()) as Examination;
    } else {
      await handleError(response, errorHandler);
      throw Error(`Could not create new version of examination`);
    }
  },

  async deleteExamination(
    id: number,
    errorHandler: ErrorHandler
  ): Promise<boolean> {
    const response = await fetch(`/api/examination/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return true;
    } else {
      await handleError(response, errorHandler);
      throw Error(`Could not delete examination`);
    }
  },

  async refreshSession(errorHandler: ErrorHandler): Promise<void> {
    const response = await fetch(`/api/session/refresh`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return;
    } else {
      await handleError(response, errorHandler);
    }
  },

  async setCurrentPatientInWebdoc(
    patientId: string,
    errorHandler: ErrorHandler
  ): Promise<void> {
    const response = await fetch(`/api/session/patient`, {
      method: "PUT",
      body: JSON.stringify({
        id: patientId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return;
    } else {
      await handleError(response, errorHandler);
    }
  },

  async returnReferral(
    referralId: number,
    record: Record,
    errorHandler: ErrorHandler
  ): Promise<Referral> {
    const response = await fetch(`/api/referral/${referralId}/actions/return`, {
      body: JSON.stringify(record),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return (await response.json()) as Referral;
    } else {
      await handleError(response, errorHandler);
      throw Error(`Could not return referral`);
    }
  },

  async getReturnRecordTemplate(errorHandler: ErrorHandler): Promise<Record> {
    const response = await fetch("/api/configuration/returnRecordTemplate");
    if (response.ok) {
      return (await response.json()) as Record;
    } else {
      await handleError(response, errorHandler);
      throw Error("Could not fetch return record template");
    }
  },
};
