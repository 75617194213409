import { Product } from "../products/product";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface Patient {
  id: string;
  gender: Gender;
  firstName: string;
  surName: string;
  address?: string;
  postalCode?: string;
  city?: string;
  phoneHome?: string;
  phoneWork?: string;
  medicalInfo?: string;
  birthDate?: string;
}

export interface Sender {
  id: string;
  name: string;
  organization: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
}

export enum State {
  NEW = "NEW",
  VERIFIED = "VERIFIED",
  NEED_SECOND_VERIFICATION = "NEED_SECOND_VERIFICATION",
  RETURNED = "RETURNED",
}

export interface Referral {
  id: number;
  orderTime: string;
  patient: Patient;
  diagnosis: string;
  anamnesis: string;
  comment: string;
  note: string;
  requestedTreatmentDate: string;
  urgent: boolean;
  sender: Sender;
  products: Product[];
  state: State;
  clinicId?: string;
  clinicName?: string;
  previousExams: string;
  replyBefore?: string;
  requestedExaminations?: string;
  invoiceRecipientId: string;
}

export interface UpdateReferral {
  note: string;
  urgent: boolean;
  products: Product[];
  state: State;
  clinicId?: string;
  clinicName?: string;
}
