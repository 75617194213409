import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Referral, State } from "../../referrals/referral";
import { Dispatch } from "react";
import { HttpClient } from "../../../http-client/http-client";
import { displayError } from "../../error-drawer/error.slice";

export interface AddExaminationState {
  referrals: Referral[];
  loading: boolean;
  complete: boolean;
}

export const AddExaminationSlice = createSlice({
  name: "add-examination",
  initialState: {
    referrals: [],
    loading: false,
    complete: false,
  } as AddExaminationState,
  reducers: {
    referralsLoading: (state) => {
      state.loading = true;
    },
    referralsFinishedLoading: (state, action: PayloadAction<Referral[]>) => {
      state.loading = false;
      state.referrals = action.payload;
    },
    setComplete: (state) => {
      state.complete = true;
    },
    setIncomplete: (state) => {
      state.complete = false;
    },
  },
});
export const { setIncomplete } = AddExaminationSlice.actions;

export const getReferralsForPatient = (patientId: string) => async (
  dispatch: Dispatch<any>
) => {
  const referrals = await HttpClient.getReferralsForPatient(
    patientId,
    State.VERIFIED,
    (error) => {
      dispatch(displayError(`Could not fetch referrals: ${error}`));
    }
  );
  dispatch(AddExaminationSlice.actions.referralsFinishedLoading(referrals));
};

export const createExaminationForProductOnReferral = (
  referralId: number,
  productId: string
) => async (dispatch: Dispatch<any>) => {
  const success = await HttpClient.createExamination(
    referralId,
    productId,
    (error) => {
      dispatch(displayError(`Could not create examination: ${error}`));
    }
  );
  if (success) {
    dispatch(AddExaminationSlice.actions.setComplete());
  }
};
