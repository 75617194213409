import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../store";
import React, { Dispatch, useEffect, useState } from "react";
import {
  convertExaminationToReturnExamination,
  createNewVersionOfExamination,
  deleteExamination,
  getExamination,
  saveDraftExamination,
  saveFinalExamination,
  setExaminationLoading,
  setIsPreview,
  setQuestionValue,
  setSelectedExamination,
  setSelectedVisit,
  updateExaminationAndEnterPreview,
} from "./examination-editor.slice";
import { RecordInput } from "../../components/record-input";
import styled from "styled-components";
import { BlockButton, Button } from "../../components/buttons";
import Select from "react-select";
import { QuestionTitle, Title } from "../../components/form-components";
import { useHistory } from "react-router-dom";
import { ReferralMetadataViewer } from "../../components/referral-metadata-viewer";
import { ExaminationResponseViewer } from "../../components/examination-response-viewer";
import { RecordType, Visit } from "./examination";
import { DateTime } from "luxon";
import { confirmDialog } from "../../components/confirm-dialog";

const ContentWrapper = styled.div`
  padding: 20px;
  margin-bottom: 300px;
`;

const ResponseWrapper = styled.div`
  padding: 20px;
`;

const WarningButton = styled(Button)`
  float: right;
  background: #f1463a;
`;

const ReturnButton = styled(Button)`
  float: right;
  background: #f1463a;
`;

const TopButton = styled(Button)`
  margin: 0 5px 10px 0;
`;

const WarningText = styled.div`
  margin-top: 10px;
  color: #f1463a;
  font-weight: bold;
`;

export const ExaminationEditorComponent = ({
  patientId,
  examinationId,
}: {
  patientId: string;
  examinationId: number;
}) => {
  const {
    isDirty,
    examination,
    selectedVisit,
    availableVisits,
    availableVisitsLoading,
    isPreview,
    loading,
  } = useSelector((state: RootState) => ({
    examination: state.examinationEditor.examination,
    selectedVisit: state.examinationEditor.selectedVisit,
    availableVisits: state.examinationEditor.availableVisits,
    availableVisitsLoading: state.examinationEditor.availableVisitsLoading,
    isDirty: state.examinationEditor.isDirty,
    isPreview: state.examinationEditor.isPreview,
    loading: state.examinationEditor.loading,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getExamination(examinationId, patientId));
    return () => {
      dispatch(setExaminationLoading());
      dispatch(setSelectedExamination(undefined));
    };
  }, [patientId, examinationId, dispatch]);

  useEffect(() => {
    if (
      !selectedVisit &&
      availableVisits &&
      availableVisits.length > 0 &&
      examination?.visitId
    ) {
      dispatch(
        setSelectedVisit(
          availableVisits.find((visit) => visit.id === examination.visitId)
        )
      );
    }
  }, [examination, availableVisits, selectedVisit, dispatch]);

  useEffect(() => {
    window.onbeforeunload = isDirty
      ? function () {
          return true;
        }
      : null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const [typingTimeout, setTypingTimeout] = useState<any>();

  if (loading) {
    return null;
  }

  const selectableVisits = availableVisits
    .map((visit) => ({
      label:
        DateTime.fromISO(visit.date).toISODate() +
        " - " +
        visit.clinicName +
        ", " +
        visit.careProvider +
        ", " +
        visit.recordTemplateName,
      value: visit,
    }))
    .sort((a, b) => b.label.localeCompare(a.label));

  if (isPreview && examination) {
    return (
      <ResponseWrapper>
        {examination.savedTime && (
          <>
            <ExaminationResponseViewer examination={examination} />
            <Button
              disabled={
                isDirty || (!selectedVisit && !examination.isReturnExamination)
              }
              onClick={() => {
                if (
                  !isDirty &&
                  (selectedVisit || examination.isReturnExamination)
                ) {
                  confirmDialog(
                    "Remissvaret kommer skickas till remittenten",
                    "Fortsätt",
                    "Avbryt",
                    () =>
                      dispatch(saveFinalExamination(examination, selectedVisit))
                  );
                }
              }}
            >
              Signera & Skicka svar
            </Button>
            <WarningButton onClick={() => dispatch(setIsPreview(false))}>
              Avbryt
            </WarningButton>
          </>
        )}
      </ResponseWrapper>
    );
  }

  if (examination?.saved) {
    return (
      <ResponseWrapper>
        <GoBackButton isDirty={false} />
        <Title>Remissvar</Title>
        {!examination.isLatestVersion && (
          <WarningText>
            OBS: Det finns en senare version av detta remissvar
          </WarningText>
        )}

        <br />
        <ExaminationResponseViewer examination={examination} />
        {examination.isLatestVersion && !examination.isReturnExamination && (
          <Button
            onClick={() => {
              if (examination) {
                dispatch(createNewVersionOfExamination(examination));
              }
            }}
            disabled={examination.record.type !== RecordType.NOTE}
          >
            Skapa komplementsvar
          </Button>
        )}
      </ResponseWrapper>
    );
  }

  return (
    <div>
      {examination && (
        <>
          <ContentWrapper>
            <GoBackButton isDirty={isDirty} />
            <ReferralMetadataViewer referral={examination.referral} />
            {examination.record.type !== RecordType.NOTE && (
              <WarningText>
                Denna undersökning är gjord på en journalmall som inte går att
                spara i WebDoc. Vänligen skapa en ny undersökning av samma typ
                på remissen eller kontakta support.
              </WarningText>
            )}
            <Title>
              {examination.version > 1 ? "Komplementsvar - " : ""}{" "}
              {examination.product.name}
            </Title>
            <RecordInput
              record={examination.record}
              onChange={(id, value) => {
                dispatch(
                  setQuestionValue({
                    id,
                    value,
                  })
                );
                onValueChange(
                  typingTimeout,
                  setTypingTimeout,
                  selectedVisit,
                  examination?.isReturnExamination,
                  dispatch
                );
              }}
            />

            {!examination.saved &&
            examination.record.type === RecordType.NOTE ? (
              <>
                {!examination.isReturnExamination && (
                  <>
                    <QuestionTitle>Välj besök</QuestionTitle>
                    <Select
                      options={selectableVisits}
                      value={
                        selectedVisit
                          ? selectableVisits.find((visit) => {
                              return visit.value === selectedVisit;
                            })
                          : null
                      }
                      onChange={(event) => {
                        if (event) {
                          dispatch(setSelectedVisit(event.value));
                        } else {
                          dispatch(setSelectedVisit(undefined));
                        }
                      }}
                      isLoading={availableVisitsLoading}
                      isClearable={true}
                      noOptionsMessage={() => "Inga besök hittade"}
                      placeholder={""}
                    />
                  </>
                )}
                <GoBackButton isDirty={isDirty} />
                <Button
                  disabled={
                    isDirty ||
                    (!selectedVisit && !examination.isReturnExamination)
                  }
                  onClick={() => {
                    if (selectedVisit || examination.isReturnExamination) {
                      dispatch(
                        updateExaminationAndEnterPreview(
                          examination,
                          selectedVisit
                        )
                      );
                    }
                  }}
                >
                  Förhandsgranska svar
                </Button>
              </>
            ) : null}
            {!examination.saved && (
              <>
                <WarningButton
                  onClick={() => {
                    confirmDialog(
                      `Undersökningen ${examination?.product.name} och all sparad data i undersökningen kommer att raderas`,
                      "Radera",
                      "Avbryt",
                      () => {
                        dispatch(deleteExamination(examination));
                        history.goBack();
                      },
                      true
                    );
                  }}
                >
                  Radera undersökning
                </WarningButton>
                {!examination.isReturnExamination && (
                  <ReturnButton
                    onClick={() => {
                      confirmDialog(
                        `Undersökningen ${examination?.product.name} och all sparad data i undersökningen kommer att raderas`,
                        "Radera och skapa retur",
                        "Avbryt",
                        () => {
                          dispatch(
                            convertExaminationToReturnExamination(examination)
                          );
                        },
                        true
                      );
                    }}
                  >
                    Skapa retur
                  </ReturnButton>
                )}
              </>
            )}
          </ContentWrapper>
        </>
      )}
    </div>
  );
};

const GoBackButton = ({ isDirty }: { isDirty: boolean }) => {
  const history = useHistory();
  return (
    <TopButton
      onClick={() => {
        if (!isDirty) {
          history.goBack();
        }
      }}
      disabled={isDirty}
    >
      Tillbaka
    </TopButton>
  );
};

const onValueChange = (
  typingTimeout: any,
  setTypingTimeout: any,
  visit: Visit | undefined,
  isReturnExamination: boolean,
  dispatch: Dispatch<any>
) => {
  if (typingTimeout) {
    clearTimeout(typingTimeout);
  }
  setTypingTimeout(
    setTimeout(() => {
      const examination = store.getState().examinationEditor.examination;
      if (examination) {
        dispatch(
          saveDraftExamination(
            examination,
            isReturnExamination ? undefined : visit
          )
        );
      }
    }, 1000)
  );
};
