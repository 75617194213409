import { Referral } from "../referral";
import { Clinic } from "../../admin/admin";
import { useDispatch, useSelector } from "react-redux";
import {
  setQuestionValue,
  setReferralClinic,
  setReferralClinicWithoutMakingReferralDirty,
  setReturnStatus,
} from "../referral-editor.slice";
import { Modal } from "../../../components/modal";
import { RecordInput } from "../../../components/record-input";
import { RootState } from "../../../store";
import { Button } from "../../../components/buttons";
import { Bold, Text } from "../../../components/typography";
import { returnReferral } from "../referral.slice";
import Select from "react-select";
import { confirmDialog } from "../../../components/confirm-dialog";

export const ReferralReturnModal = ({
  referral,
  clinics,
}: {
  referral: Referral;
  clinics: Clinic[];
}) => {
  const dispatch = useDispatch();

  const { returnRecord, loading } = useSelector((state: RootState) => ({
    returnRecord: state.referralEditor.returnRecord,
    loading: state.referral.loading,
  }));

  const selectableClinics = clinics.map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  if (!referral.clinicId && selectableClinics.length > 0) {
    const clinic = selectableClinics[0];
    dispatch(
      setReferralClinicWithoutMakingReferralDirty({
        id: clinic.value,
        name: clinic.label,
      })
    );
  }

  return (
    <Modal onClose={() => dispatch(setReturnStatus(false))}>
      <Bold>Patient</Bold>
      <Text>
        {referral.patient.id}, {referral.patient.firstName}{" "}
        {referral.patient.surName}
      </Text>
      <Bold>Remittent</Bold>
      <Text>
        {referral.sender.name}, {referral.sender.organization}
      </Text>
      {returnRecord && (
        <>
          <RecordInput
            record={returnRecord}
            onChange={(id, value) => {
              dispatch(setQuestionValue({ id, value }));
            }}
          />
          <Text />
          <Bold>Klinik</Bold>
          <Select
            onChange={(clinic) => {
              if (clinic) {
                if (clinic.label && clinic.value) {
                  dispatch(
                    setReferralClinic({
                      id: clinic.value,
                      name: clinic.label,
                    })
                  );
                }
              }
            }}
            value={selectableClinics.find(
              (clinic) => clinic.value === referral.clinicId
            )}
            options={selectableClinics}
            placeholder={"Välj klinik"}
          />
          <Button
            disabled={loading}
            onClick={() =>
              confirmDialog(
                "Remissen kommer skickas tillbaka till remittenten",
                "Forstätt",
                "Avbryt",
                () => dispatch(returnReferral(referral, returnRecord))
              )
            }
          >
            Signera och skicka retur
          </Button>
        </>
      )}
      <Button
        disabled={loading}
        onClick={() => dispatch(setReturnStatus(false))}
      >
        Avbryt
      </Button>
    </Modal>
  );
};
