import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { Table } from "../../../components/table";
import { Button } from "../../../components/buttons";
import { UrgentSymbol } from "../../../styles";
import {
  getExaminationsWithState,
  setOrderBy,
} from "../../examination/examination.slice";
import { Examination } from "../../examination/examination";
import { HttpClient } from "../../../http-client/http-client";
import { displayError } from "../../error-drawer/error.slice";
import { Filters } from "../../../components/filters";
import { setFilters } from "../referral-list.slice";
import styled from "styled-components";

const Ruler = styled.div`
  height: 10px;
`;

export const ExaminationTab = ({ saved }: { saved: boolean }) => {
  const {
    examinations,
    count,
    pageSize,
    orderBy,
    orderDirection,
    products,
    filters,
  } = useSelector((state: RootState) => ({
    examinations: state.examination.examinations.filter(
      (examination) => examination.saved === saved
    ),
    count: state.examination.totalCount,
    pageSize: state.examination.pageSize,
    orderBy: state.examination.orderBy,
    orderDirection: state.examination.orderDirection,
    products: state.product.products,
    filters: state.referralList.filters,
  }));

  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getExaminationsWithState(
        offset,
        pageSize,
        saved,
        orderBy,
        orderDirection,
        filters
      )
    );
  }, [dispatch, offset, orderBy, orderDirection, pageSize, saved, filters]);

  const sortColumns = saved
    ? [
        "examination.patientId",
        "referral.patientFirstName",
        "referral.orderTime",
        "referral.senderOrganization",
        "product.name",
        "referral.urgent",
      ]
    : [
        "examination.patientId",
        "referral.patientFirstName",
        "referral.orderTime",
        "referral.senderOrganization",
        "product.name",
        "examination.record",
        "referral.urgent",
      ];
  const sortableColumns = [true, true, true, true, true, true, true];
  const availableFilters = [
    {
      name: "Undersökning",
      values: products.map((product) => ({
        label: product.name,
        type: "product",
        typeName: "Undersökning",
        value: product.code,
      })),
    },
  ];

  const headers = saved
    ? [
        "Personnummer",
        "Namn",
        "Remissdatum",
        "Organisation",
        "Undersökning",
        "Akut",
      ]
    : [
        "Personnummer",
        "Namn",
        "Remissdatum",
        "Organisation",
        "Undersökning",
        "Svar påbörjat",
        "Akut",
      ];

  return (
    <>
      <Filters
        filters={filters}
        availableFilters={availableFilters}
        callback={(filter) => {
          setOffset(0);
          dispatch(setFilters([...filters, filter]));
        }}
        deleteFilter={(idx) => {
          setOffset(0);
          dispatch(
            setFilters([...filters.slice(0, idx), ...filters.slice(idx + 1)])
          );
        }}
      />
      <Ruler />
      <Table
        headers={headers}
        rows={examinations.map(examinationRow)}
        count={count}
        offset={offset}
        pageSize={pageSize}
        setOffset={(newOffset) => setOffset(newOffset)}
        onSelectRow={(idx) => {
          HttpClient.setCurrentPatientInWebdoc(
            examinations[idx].referral.patient.id,
            (err) => {
              dispatch(displayError("Kunde inte byta till vald patient"));
            }
          );
        }}
        sortOn={(columnIdx, direction) => {
          dispatch(setOrderBy(sortColumns[columnIdx]));
        }}
        sortableColumns={sortableColumns}
        sortDirection={orderDirection}
        sortColumn={sortColumns.indexOf(orderBy)}
        columnWidths={[120, 180, 120, 180, undefined, 60]}
      />
      {saved && (
        <a href="/api/examination/export-metadata">
          <Button>Exportera besöksdata</Button>
        </a>
      )}
    </>
  );
};

const examinationRow = (examination: Examination) => {
  return examination.saved
    ? [
        examination.referral.patient.id,
        examination.referral.patient.firstName +
          " " +
          examination.referral.patient.surName,
        examination.referral.orderTime.toString().substring(0, 10),
        examination.referral.sender.organization,
        examination.product.name,
        examination.referral.urgent ? <UrgentSymbol /> : null,
      ]
    : [
        examination.referral.patient.id,
        examination.referral.patient.firstName +
          " " +
          examination.referral.patient.surName,
        examination.referral.orderTime.toString().substring(0, 10),
        examination.referral.sender.organization,
        examination.product.name,
        examination.hasDraft ? "*" : "",
        examination.referral.urgent ? <UrgentSymbol /> : null,
      ];
};
